import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import InputMask from "react-input-mask";
import { GetSelectedLanguage } from "../../helper/Method";
import { t } from "i18next";
import { applyClassToCharacters, isUrdu } from "../../../constants/const";

const MemberInfo = ({
  tab1,
  memberValues,
  editValue,
  setEditValue,
  id,
  currentRecordRegNo,
  memberTypeId,
}) => {
  const currentRecordId = parseInt(id);
  const [previousRecordRegNo, setPreviousRecordRegNo] = useState(null);
  const [isPreviousRecord, setIsPreviousRecord] = useState(false);

  useEffect(() => {
    if (currentRecordId === editValue) {
      if (isPreviousRecord) {
        setPreviousRecordRegNo(memberValues.currentRecordRegNo);
      } else setPreviousRecordRegNo(memberValues.previousRecordRegNo);
    } else {
      setPreviousRecordRegNo("");
    }
  }, [memberValues, editValue, currentRecordId, isPreviousRecord]);

  useEffect(() => {
    if (memberValues?.currentRecordId !== null) {
      setIsPreviousRecord(true);
    }
  }, [memberValues]);

  return (
    <div role="tabpanel" id="tab-1" className={"tab-pane " + tab1}>
      <div className="panel-body">
        <Row>
          <Col md="3">
            <FormGroup>
              <Label className="col-form-label">
                {<GetLanguageString props="settings_member_type" />}
              </Label>
              <Input
                className="ur"
                type="text"
                name="phone2"
                value={memberValues.memberType}
                disabled
              />
            </FormGroup>
          </Col>
          {memberTypeId !== 11 ? (
            <>
              <Col md="3">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="member_marital_status" />}
                  </Label>
                  <Input
                    className="ur"
                    type="text"
                    name="phone2"
                    value={memberValues.maritalStatus}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="member_sons" />}
                  </Label>
                  <Input
                    className="ur"
                    type="text"
                    name="phone2"
                    value={memberValues.sons}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="member_daughters" />}
                  </Label>
                  <Input
                    className="ur"
                    type="text"
                    name="phone2"
                    value={memberValues.daughters}
                    disabled
                  />
                </FormGroup>
              </Col>
            </>
          ) : (
            <Col md="3">
              <FormGroup>
                <Label className="col-form-label">
                  {<GetLanguageString props="settings_gender" />}
                </Label>
                <Input
                  type="text"
                  name="gender"
                  value={
                    memberValues.gender === "m"
                      ? t("settings_gender_male")
                      : t("settings_gender_female")
                  }
                  disabled
                />
              </FormGroup>
            </Col>
          )}
          <Col md="3">
            <FormGroup>
              <Label className="col-form-label">
                {<GetLanguageString props="member_education" />}
              </Label>
              <Input
                className={isUrdu(memberValues.education) ? "ur" : ""}
                type="text"
                name="education"
                value={memberValues.education}
                disabled
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label className="col-form-label">
                {<GetLanguageString props="education_field" />}
              </Label>
              <Input
                className={isUrdu(memberValues.educationField) ? "ur" : ""}
                type="text"
                name="educationField"
                value={memberValues.educationField}
                disabled
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label className="col-form-label">
                {<GetLanguageString props="member_occupation" />}
              </Label>
              <Input
                className={isUrdu(memberValues.occupation) ? "ur" : ""}
                type="text"
                name="phone2"
                value={memberValues.occupation}
                disabled
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label className="col-form-label">
                {<GetLanguageString props="member_cnic" />}
              </Label>
              <InputMask
                mask="99999-9999999-9"
                value={memberValues.cnic}
                name="cnic"
                maskChar=""
                disabled
              >
                {(inputProps) => <Input {...inputProps} type="text" disabled />}
              </InputMask>
            </FormGroup>
          </Col>
          {memberTypeId === 11 ? (
            <Col md="3">
              <FormGroup>
                <Label className="col-form-label">
                  {<GetLanguageString props="ahbab_associated_member" />}
                </Label>
                <div
                  style={{
                    backgroundColor: "#e9ecef",
                    backgroundImage: "none",
                    border: "1px solid #e5e6e7",
                    borderRadius: "1px",
                    color: "inherit",
                    display: "block",
                    padding: "6px 12px",
                    transition:
                      "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                    width: "100%",
                    height: "35px",
                  }}
                >
                  {memberValues.associatedMember
                    ? applyClassToCharacters(
                        memberValues.associatedMember,
                        "ur"
                      )
                    : ""}
                </div>
              </FormGroup>
            </Col>
          ) : null}
        </Row>
        {memberTypeId !== 11 ? (
          <Row>
            {" "}
            <Col md="3">
              <FormGroup>
                <Label className="col-form-label">
                  {<GetLanguageString props="member_blood_group" />}
                </Label>
                <Input
                  className="ur"
                  type="text"
                  name="phone2"
                  value={memberValues.bloodGroup}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md="6">
              {currentRecordId === editValue ? (
                <Row>
                  <Col md="8">
                    <FormGroup>
                      {isPreviousRecord ? (
                        <Label className="col-form-label">
                          {<GetLanguageString props="member_current_record" />}
                        </Label>
                      ) : (
                        <Label className="col-form-label">
                          {<GetLanguageString props="member_previous_record" />}
                        </Label>
                      )}
                      <Input
                        className={GetSelectedLanguage() === "en" ? "" : "ur"}
                        type="text"
                        name=""
                        disabled
                        value={previousRecordRegNo}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    {previousRecordRegNo ? (
                      <>
                        <Button
                          style={{ marginTop: "33px" }}
                          color="success"
                          onClick={() => {
                            setEditValue(
                              isPreviousRecord
                                ? memberValues.currentRecordId
                                : memberValues.previousRecordId
                            );
                          }}
                        >
                          {<GetLanguageString props="member_view" />}
                        </Button>
                      </>
                    ) : null}
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col md="8">
                    <FormGroup>
                      {isPreviousRecord ? (
                        <Label className="col-form-label">
                          {<GetLanguageString props="member_current_record" />}
                        </Label>
                      ) : (
                        <Label className="col-form-label">
                          {<GetLanguageString props="member_previous_record" />}
                        </Label>
                      )}
                      <Input
                        className={GetSelectedLanguage() === "en" ? "" : "ur"}
                        type="text"
                        name=""
                        disabled
                        value={currentRecordRegNo}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <Button
                      color="success"
                      style={{ marginTop: "33px" }}
                      onClick={() => {
                        setEditValue(currentRecordId);
                      }}
                    >
                      {<GetLanguageString props="member_view" />}
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        ) : null}
      </div>
    </div>
  );
};

export default MemberInfo;
