import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
// import Select from "react-select";

import { GetLanguageString } from "../../../helper/Components";
import { DateValidater } from "../../../helper/Method";
import SearchAhbabFromContactForm from "../SearchAhbabFromContactForm";
import { encryptStorage } from "../../../../constants/EncryptStorage";
// import { setupApi } from "../../../member/ApiMember";
import { isUrdu } from "../../../../constants/const";

const SearchAhbabFromContactFormReport = ({
  searchAhbabModel,
  setSearchAhbabModel,
  setSearchButton,
  setLoading,
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [values, setValues] = useState(searchAhbabModel);
  const dateRangeValidation = {
    startDate: null,
    endDate: null,
  };
  // const selectedLanguage = GetSelectedLanguage();
  const [dateValidations, setDateValidations] = useState(dateRangeValidation);
  // const [countries, setCountries] = useState([]);
  // const [states, setStates] = useState([]);
  // const [cities, setCities] = useState([]);
  // const [selectedCountryId, setSelectedCountryId] = useState();
  // const [selectedStateId, setSelectedStateId] = useState();
  // const [countriesLoading, setCountriesLoading] = useState(false);

  const handleInputChangeStartDate = (value) => {
    if (DateValidater(value, values.endDate)) {
      if (value <= new Date()) {
        let date = new Date(value.setHours(value.getHours() + 5));
        setValues({
          ...values,
          startDate: date,
        });
      }
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        startDate: (
          <GetLanguageString props="common_start_date_validation_message" />
        ),
      });
    }
  };
  // useEffect(() => {
  //   setCountriesLoading(true);
  //   setupApi()
  //     .GetCountries()
  //     .then((res) => {
  //       setCountries(res.data.countries);
  //       setSelectedCountryId(res.data.selectedCountry.value);
  //       setValues({
  //         ...values,
  //         country: res.data.selectedCountry,
  //       });
  //       if (values.state === "" || values.city === "") {
  //         setStates([]);
  //         setCities([]);
  //         setSelectedStateId(0);
  //       }
  //       setCountriesLoading(false);
  //     })
  //     .catch((err) => console.log(err));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   setupApi()
  //     .GetStates(selectedCountryId)
  //     .then((res) => {
  //       setStates(res.data);
  //       if (res.data.length === 0) {
  //         setCities([]);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }, [selectedCountryId]);

  // useEffect(() => {
  //   setupApi()
  //     .GetCities(selectedStateId)
  //     .then((res) => {
  //       setCities(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, [selectedStateId]);

  // const handleOnChangeCountry = (country) => {
  //   setValues({
  //     ...values,
  //     country: country,
  //     state: "",
  //     city: "",
  //   });
  //   setCities([]);
  //   setSelectedStateId(0);
  //   setSelectedCountryId(country.value);
  // };

  // const handleOnChangeCity = (city) => {
  //   setValues({
  //     ...values,
  //     city: city,
  //   });
  // };
  // const handleOnChangeState = (state) => {
  //   setValues({
  //     ...values,
  //     state: state,
  //     city: "",
  //   });
  //   setSelectedStateId(state.value);
  // };

  const handleInputChangeEndDate = (value) => {
    if (DateValidater(values.startDate, value)) {
      if (value <= new Date()) {
        let date = new Date(value.setHours(value.getHours() + 5));
        setValues({
          ...values,
          endDate: date,
        });
      }
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        endDate: (
          <GetLanguageString props="common_end_date_validation_message" />
        ),
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    setSearchButton(true);
    setSearchAhbabModel(values);
  };

  return (
    <>
      <Row>
        <Col md="3">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="common_search_by_name" />}
            </Label>
            <Input
              className={isUrdu(values.habibName) ? "ur" : ""}
              type="text"
              name="habibName"
              value={values.habibName}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="member_search_by_phoneno_email" />}
            </Label>
            <Input
              type="text"
              name="mobileNumber"
              value={values.mobileNumber}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        <Col md="3">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="courses_start_date" />}
            </Label>
            <DatePicker
              showYearDropdown
              placeholderText="dd/mm/yyyy"
              className="form-control"
              selected={values.startDate}
              onChange={handleInputChangeStartDate}
              dateFormat="dd/MM/yyyy"
            />
            {dateValidations.startDate && (
              <h4 className="text-error">{dateValidations.startDate}</h4>
            )}
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="courses_end_date" />}
            </Label>
            <DatePicker
              showYearDropdown
              placeholderText="dd/mm/yyyy"
              className="form-control"
              selected={values.endDate}
              onChange={handleInputChangeEndDate}
              dateFormat="dd/MM/yyyy"
            />
            {dateValidations.endDate && (
              <h4 className="text-error">{dateValidations.endDate}</h4>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {" "}
        <Col lg="6">
          <FormGroup>
            <Label className="col-form-label">
              {<GetLanguageString props="ahbab_address" />}
            </Label>
            <Input
              className={isUrdu(values.address) ? "ur" : ""}
              type="text"
              name="address"
              value={values.address}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>{" "}
        {/* <Col md="6">
          {!countriesLoading ? (
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="member_country" />}
                  </Label>
                  <Select
                    options={countries}
                    value={values.country}
                    menuPlacement="top"
                    className="basic-single"
                    placeholder={
                      selectedLanguage === "en" ? "Select" : "منتخب کریں"
                    }
                    classNamePrefix="select"
                    onChange={(country) => {
                      handleOnChangeCountry(country);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                {states.length === 0 ? (
                  <h3 className="text-center m-5">{" - "}</h3>
                ) : (
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="ahbeb_state_province" />}
                    </Label>
                    <Select
                      options={states}
                      value={values.state}
                      className="basic-single"
                      menuPlacement="top"
                      placeholder={
                        states.length === 0
                          ? selectedLanguage === "en"
                            ? "No States Available"
                            : "کوئی صوبہ موجود نہی ہے"
                          : selectedLanguage === "en"
                          ? "Select"
                          : "منتخب کریں"
                      }
                      classNamePrefix="select"
                      onChange={(state) => {
                        handleOnChangeState(state);
                      }}
                    />
                  </FormGroup>
                )}
              </Col>
              <Col md="4">
                {cities.length === 0 ? (
                  <h3 className="text-center m-5">{" - "}</h3>
                ) : (
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="member_city" />}
                    </Label>
                    <Select
                      options={cities}
                      value={values.city}
                      className="basic-single"
                      menuPlacement="top"
                      placeholder={
                        cities.length === 0
                          ? selectedLanguage === "en"
                            ? "No Cities Available"
                            : "کوئی شہر موجود نہی ہے"
                          : selectedLanguage === "en"
                          ? "Select"
                          : "منتخب کریں"
                      }
                      classNamePrefix="select"
                      onChange={(city) => {
                        handleOnChangeCity(city);
                      }}
                    />
                  </FormGroup>
                )}
              </Col>
            </Row>
          ) : (
            LoadingSpinner()
          )}
        </Col> */}
      </Row>
      <br></br>
      <Row>
        <SearchAhbabFromContactForm
          values={values}
          setValues={setValues}
          unitId={loggedInUnitId}
        />
      </Row>
      <br></br>
      <Row>
        <Col className="text-center">
          <FormGroup>
            <Button
              color="primary"
              className="m-l-sm"
              type="submit"
              onClick={handleSubmit}
            >
              {<GetLanguageString props="common_search" />}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default SearchAhbabFromContactFormReport;
