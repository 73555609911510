import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import swal from "sweetalert";
import { customMultiValue, customOption } from "../../constants/const";
import { ApiDocumentTag } from "../config/documentTags/ApiDocumentTag";

const CreateableTags = ({ selectedTags, setSelectedTags }) => {
  const [tags, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);

  useEffect(() => {
    ApiDocumentTag()
      .GetDocumentTags()
      .then((res) => {
        setAllTags(res.data);
        const updatedTags = res.data.filter(
          (item1) => !selectedTags.some((item2) => item2.label === item1.label)
        );
        setTags(updatedTags);
        setSelectedTags((prevTags) =>
          prevTags.map((prevTag) => {
            const match = res.data.find(
              (newTag) => newTag.label === prevTag.label
            );
            return match ? { ...prevTag, value: match.value } : prevTag;
          })
        );
      })
      .catch((err) => {
        swal({ title: err, icon: "error", buttons: "OK" });
      });
    // eslint-disable-next-line
  }, []);

  const handleChange = (newValue) => {
    setSelectedTags(newValue || []);
    const updatedTags = allTags.filter(
      (item1) => !newValue.some((item2) => item2.label === item1.label)
    );
    setTags(updatedTags);
  };

  const handleCreate = (inputValue) => {
    const newTag = { label: inputValue, value: 0 };
    setSelectedTags((prev) => [...prev, newTag]);

    ApiDocumentTag()
      .AddDocumentTag(newTag)
      .then((res) => {
        setAllTags(res.data);
        handleChange([...selectedTags, newTag]);
        setSelectedTags((prevTags) =>
          prevTags.map((prevTag) => {
            const match = res.data.find(
              (newTag) => newTag.label === prevTag.label
            );
            return match ? { ...prevTag, value: match.value } : prevTag;
          })
        );
      })
      .catch((err) => {
        swal({ title: err, icon: "error", buttons: "OK" });
      });
  };

  // const handleRemoveTag = (tagValue) => {
  //   if (tagValue.isDeletable) {
  //     setTags(tags.filter((tag) => tag.value !== tagValue.value));
  //     setSelectedTags(
  //       selectedTags.filter((tag) => tag.value !== tagValue.value)
  //     );
  //     setupApi()
  //       .DeleteDocumentTag(tagValue.value)
  //       .then((res) => {
  //         setAllTags(res.data);
  //         setTags(
  //           res.data.filter(
  //             (item1) =>
  //               !selectedTags.some((item2) => item2.label === item1.label)
  //           )
  //         );
  //         setSelectedTags((prevTags) =>
  //           prevTags.map((prevTag) => {
  //             const match = res.data.find(
  //               (newTag) => newTag.label === prevTag.label
  //             );
  //             return match ? { ...prevTag, value: match.value } : prevTag;
  //           })
  //         );
  //       })
  //       .catch((err) => {
  //         swal({ title: err.response.data, icon: "error", buttons: "OK" });
  //       });
  //   } else {
  //     swal({
  //       title: t("tag_have_documents"),
  //       className: GetSelectedLanguage() === "en" ? "" : "ur",
  //       icon: "error",
  //       buttons: "OK",
  //     });
  //   }
  // };

  return (
    <CreatableSelect
      id="tag-selector"
      isMulti
      options={tags}
      value={selectedTags}
      onChange={handleChange}
      onCreateOption={handleCreate}
      isClearable
      components={{
        Option: customOption,
        MultiValue: customMultiValue,
      }}
    />
  );
};

export default CreateableTags;
