import moment from "moment";
import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  Table,
} from "reactstrap";

import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { RiFileExcel2Line } from "react-icons/ri";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer } from "react-toastify";
import swal from "sweetalert";
import {
  CheckIsAdmin,
  CheckPermission,
  LoadingSpinner,
  applyClassToCharacters,
  convertUTCToLocalTime,
} from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import {
  GetLanguageString,
  GetSortingUI,
  NoResult,
} from "../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import useSorting from "../../shared/useSorting";
import { ApiAhbab } from "../ApiAhbab";
import TransferHabibModal from "../TransferHabibModal";
import SearchAhbabFromContactFormReport from "./report/SearchAhbabFromContactFormReport";
import SelectAssociatedMember from "./SelectAssociatedMember";
import MoveMemberModal from "../../member/MoveMemberModal";

const initialValues = {
  date: "neutral",
  habibName: "neutral",
};

const AhbabFromContactForm = ({ history }) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const unitTypeName = encryptStorage.getItem("unitTypeName");
  const [loading, setLoading] = useState(false);
  const [ahbab, setAhbab] = useState([]);
  const [transferHabibModal, setTransferHabibModal] = useState(false);
  const [habibId, setHabibId] = useState();
  const [habibUnitId, setHabibUnitId] = useState();
  const [previousUnitId, setPreviousUnitId] = useState();
  const [moveHabibModal, setMoveHabibModal] = useState(false);
  const [check, setCheck] = useState();
  const [searchButton, setSearchButton] = useState(false);
  const [pagination, setPagination] = useState({ activePage: 1 });
  const [update, setUpdate] = useState(false);
  const [searchAhbabModel, setSearchAhbabModel] = useState({
    startDate: null,
    endDate: null,
    address: "",
    habibName: "",
    mobileNumber: "",
    activePage: 1,
    isSubUnits: false,
    unit: { value: loggedInUnitId, label: "" },
  });
  const { sortObject, handleSorting } = useSorting(initialValues);
  const [showAssociatedMemberModal, setShowAssociatedMemberModal] =
    useState(false);
  let recordIndex = pagination.pageSize * (pagination.activePage - 1) + 1;

  const toggleDropdownOpen = (index) => {
    let temp = [...ahbab];
    temp[index].dropDown = !ahbab[index].dropDown;
    setAhbab(temp);
  };

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    let tempSortObject = {
      isDateDescending: sortObject.date === "descending",
      isDateAscending: sortObject.date === "ascending",
      isHabibNameAscending: sortObject.habibName === "ascending",
      isHabibNameDescending: sortObject.habibName === "descending",
    };
    searchAhbabModel.sortModel = tempSortObject;
    if (searchButton) {
      ApiAhbab()
        .GetContactFormAhbab(searchAhbabModel)
        .then((res) => {
          setAhbab(res.data.list);
          setPagination(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
        });
    }
  }, [searchAhbabModel, sortObject, searchButton, update]);

  const handleOnMoveHabib = (name, value, habibId) => {
    setHabibUnitId(value);
    setCheck(name);
    setHabibId(habibId);
    setMoveHabibModal(true);
  };

  const excelDownload = () => {
    let tempSortObject = {
      isDateDescending: sortObject.date === "descending",
      isDateAscending: sortObject.date === "ascending",
      isHabibNameAscending: sortObject.habibName === "ascending",
      isHabibNameDescending: sortObject.habibName === "descending",
    };
    searchAhbabModel.sortModel = tempSortObject;

    ApiAhbab()
      .GetAhbabExcelReport(searchAhbabModel)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "AhbabFromContactForm.xlsx");
      })
      .catch((err) => console.log(err));
  };

  const handleOnDelete = (id) => {
    swal({
      title: t("common_delete_message"),
      text: t("common_delete_detail_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        ApiAhbab()
          .CancelHabib(id)
          .then((res) => {
            swal({
              title: t("ahbab_delete_success_message"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: "OK",
            });
            setUpdate(!update);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            swal({
              title: err.response.data,
              icon: "error",
              buttons: "OK",
            });
            setLoading(false);
          });
      }
    });
  };
  const handlePageChange = (pageNumber) => {
    setSearchAhbabModel({
      ...searchAhbabModel,
      activePage: pageNumber,
    });
    setPagination({
      ...pagination,
      activePage: pageNumber,
    });
  };

  return (
    <>
      <ToastContainer
        autoClose={3000}
        bodyClassName={GetSelectedLanguage()}
        closeOnClick
        pauseOnHover={false}
        draggable
      />
      {showAssociatedMemberModal && (
        <SelectAssociatedMember
          memberHabibId={habibId}
          habibUnitId={habibUnitId}
          setShowAssociatedMemberModal={setShowAssociatedMemberModal}
          setRefreshList={setUpdate}
          refreshList={update}
        />
      )}
      {moveHabibModal && (
        <MoveMemberModal
          memberId={habibId}
          unitId={habibUnitId}
          check={check}
          setRefresh={setUpdate}
          refresh={update}
          setMoveMemberModal={setMoveHabibModal}
          calledAs={"tanzeemOrgHabib"}
        />
      )}
      {transferHabibModal ? (
        <TransferHabibModal
          setTransferHabibModal={setTransferHabibModal}
          habibId={habibId}
          previousUnitId={previousUnitId}
          setUpdate={setUpdate}
          update={update}
        />
      ) : null}

      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox-title">
          <h5>{<GetLanguageString props="ahbab_search" />}</h5>
          {/* <Button
            color="primary"
            size="sm"
            className={GetCustomStyles().btn_style}
            onClick={() =>
              history.push({
                pathname: `ahbabFromContactForm/addHabib`,
              })
            }
          >
            {<GetLanguageString props="ahbab_add" />}
          </Button> */}
        </div>
        <div className="ibox-content">
          <SearchAhbabFromContactFormReport
            searchAhbabModel={searchAhbabModel}
            setSearchAhbabModel={setSearchAhbabModel}
            setSearchButton={setSearchButton}
            setLoading={setLoading}
          />
        </div>
      </div>
      {searchButton && (
        <div className="ibox" id="section-to-print">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="ahbab" />}</h5>
            {ahbab.length !== 0 ? (
              <Button
                color="default"
                size="sm"
                onClick={excelDownload}
                className={GetCustomStyles().btn_style}
              >
                <RiFileExcel2Line
                  size={15}
                  color="#009440"
                  style={{ marginRight: 3, marginBottom: 1 }}
                />
                {<GetLanguageString props="member_report_export_excel" />}
              </Button>
            ) : null}
          </div>
          <div className="ibox-content">
            {!loading ? (
              ahbab.length !== 0 ? (
                <div className="table-responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th
                          className="cursor-pointer"
                          onClick={() => handleSorting("habibName")}
                        >
                          {<GetLanguageString props="common_name" />}
                          <GetSortingUI
                            sortObject={sortObject}
                            name="habibName"
                            isReport
                          />
                        </th>
                        <th>
                          {
                            <GetLanguageString props="ahbab_associated_member" />
                          }
                        </th>
                        <th>{<GetLanguageString props="ahbab_address" />}</th>
                        <th>{<GetLanguageString props="member_city" />}</th>
                        <th>
                          {
                            <GetLanguageString props="member_member_detail_course_location" />
                          }
                        </th>
                        <th
                          className="cursor-pointer"
                          onClick={() => handleSorting("date")}
                        >
                          <GetLanguageString props="dashboard_event_page_date" />
                          <GetSortingUI
                            sortObject={sortObject}
                            name="date"
                            isReport
                          />
                        </th>
                        <th>{<GetLanguageString props="ahbab_contact1" />}</th>
                        <th>{<GetLanguageString props="unit" />}</th>
                        <th className="text-center">
                          {<GetLanguageString props="ahbab_request_status" />}
                        </th>
                        <th className="text-center">
                          {<GetLanguageString props="common_action" />}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ahbab.map((record, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + recordIndex}</td>
                            <td>
                              {record.habibName
                                ? applyClassToCharacters(record.habibName, "ur")
                                : "-"}
                            </td>
                            <td>
                              {record.associatedMember
                                ? applyClassToCharacters(
                                    record.associatedMember,
                                    "ur"
                                  )
                                : "-"}
                            </td>
                            <td>
                              {record.address
                                ? applyClassToCharacters(record.address, "ur")
                                : ""}
                            </td>
                            <td>{record.city}</td>
                            <td>{record.location}</td>
                            <td>
                              {moment(
                                convertUTCToLocalTime(record.date)
                              ).format("DD/MM/yyyy")}
                            </td>
                            <td>{record.mobileNumber}</td>
                            <td>
                              <Label
                                className="label label-info ur m-1"
                                size="sm"
                              >
                                <h5>
                                  {record.unitName.replace(/ ، /g, " / ")}
                                </h5>
                              </Label>
                            </td>
                            <td
                              className="text-center"
                              style={{
                                color:
                                  record.requestStatus === 1
                                    ? "Green"
                                    : record.requestStatus === 2
                                    ? "Red"
                                    : record.requestStatus === 4
                                    ? "Red"
                                    : record.requestStatus === 0
                                    ? "Orange"
                                    : record.requestStatus === 3
                                    ? "Gold "
                                    : null,
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              {record.requestStatus === 1 ? (
                                <GetLanguageString props="notifications_approve" />
                              ) : record.requestStatus === 2 ? (
                                <GetLanguageString props="notifications_reject" />
                              ) : record.requestStatus === 4 ? (
                                <GetLanguageString props="notifications_cancel" />
                              ) : record.requestStatus === 0 ? (
                                <GetLanguageString props="notifications_pending" />
                              ) : record.requestStatus === 3 ? (
                                <GetLanguageString props="notifications_on_hold" />
                              ) : record.requestStatus === null ? (
                                " - "
                              ) : null}
                            </td>
                            <td className="text-center">
                              <Dropdown
                                isOpen={record.dropDown}
                                toggle={() => {
                                  toggleDropdownOpen(index);
                                }}
                                style={{ position: "static" }}
                              >
                                <DropdownToggle
                                  className="btn btn-default"
                                  tag="span"
                                  aria-expanded={record.dropDown}
                                >
                                  {<GetLanguageString props="common_action" />}
                                  <b className="caret m-l-xs" />
                                </DropdownToggle>
                                <DropdownMenu
                                  style={{
                                    minWidth: "0rem",
                                    fontSize: "13px",
                                  }}
                                >
                                  <div>
                                    <Link
                                      to={{
                                        pathname: `member/membersdetails/${record.id}`,
                                        state: {
                                          unitId: record.unitId,
                                          isEditable: record.isEditable,
                                          isDeletable: record.isDeletable,
                                          canModifyType: false,
                                          memberTypeId: record.habibTypeId,
                                          calledAs: "tanzeemOrgHabib",
                                          isAssociated:
                                            record.isAssociatedWithMember,
                                        },
                                      }}
                                    >
                                      <DropdownItem>
                                        {
                                          <GetLanguageString props="member_detail_btn" />
                                        }
                                      </DropdownItem>{" "}
                                    </Link>
                                    {CheckPermission(
                                      "CanMoveTanzeemOrgAhbab"
                                    ) && record.unitId === 1 ? (
                                      <DropdownItem
                                        onClick={() => {
                                          setHabibId(record.id);
                                          setPreviousUnitId(record.unitId);
                                          setTransferHabibModal(true);
                                        }}
                                      >
                                        {
                                          <GetLanguageString props="ahbab_transfer_habib" />
                                        }
                                      </DropdownItem>
                                    ) : null}
                                    {record.unitId !== 1 ? (
                                      <div>
                                        {CheckIsAdmin() ||
                                        unitTypeName === "Markaz" ||
                                        unitTypeName === "Zone" ||
                                        unitTypeName === "ZoneOS" ? (
                                          <DropdownItem
                                            onClick={(e) =>
                                              handleOnMoveHabib(
                                                "admin",
                                                record.unitId,
                                                record.id
                                              )
                                            }
                                          >
                                            {
                                              <GetLanguageString props="ahbab_transfer_habib" />
                                            }
                                          </DropdownItem>
                                        ) : null}
                                        {!CheckIsAdmin() &&
                                        CheckPermission("CanMoveTanzeemOrgAhbab") &&
                                        unitTypeName !== "Markaz" &&
                                        unitTypeName !== "Zone" &&
                                        unitTypeName !== "ZoneOS" ? (
                                          <DropdownItem
                                            onClick={(e) =>
                                              handleOnMoveHabib(
                                                "Local",
                                                record.unitId,
                                                record.id
                                              )
                                            }
                                          >
                                            <GetLanguageString props="unit_unitmember_move_member_localy" />
                                          </DropdownItem>
                                        ) : null}
                                        {!CheckIsAdmin() &&
                                        CheckPermission(
                                          "CanRequestMoveTanzeemOrgAhbab"
                                        ) &&
                                        unitTypeName !== "Markaz" &&
                                        unitTypeName !== "Zone" &&
                                        unitTypeName !== "ZoneOS" ? (
                                          <DropdownItem
                                            onClick={(e) =>
                                              handleOnMoveHabib(
                                                "Outside",
                                                record.unitId,
                                                record.id
                                              )
                                            }
                                          >
                                            <GetLanguageString props="unit_unitmember_move_members_other" />
                                          </DropdownItem>
                                        ) : null}
                                      </div>
                                    ) : null}
                                    {record.isEditable ? (
                                      <>
                                        <DropdownItem
                                          onClick={() => {
                                            setHabibId(record.id);
                                            setHabibUnitId(record.unitId);
                                            setShowAssociatedMemberModal(true);
                                          }}
                                        >
                                          {record.isAssociatedWithMember ? (
                                            <GetLanguageString props="ahbab_update_associated_habib" />
                                          ) : (
                                            <GetLanguageString props="ahbab_associate_with_habib" />
                                          )}
                                        </DropdownItem>
                                        <Link
                                          to={{
                                            pathname: `ahbabFromContactForm/editHabib/${record.id}`,
                                          }}
                                        >
                                          <DropdownItem>
                                            {
                                              <GetLanguageString props="common_edit" />
                                            }
                                          </DropdownItem>{" "}
                                        </Link>
                                      </>
                                    ) : null}
                                    {CheckPermission(
                                      "CanDeleteTanzeemOrgAhbab"
                                    ) ? (
                                      <DropdownItem
                                        onClick={() => {
                                          handleOnDelete(record.id);
                                        }}
                                      >
                                        {
                                          <GetLanguageString props="common_delete" />
                                        }
                                      </DropdownItem>
                                    ) : null}
                                  </div>
                                </DropdownMenu>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>{" "}
                    <tfoot>
                      <tr>
                        <td colSpan="11">
                          <ul className={GetCustomStyles().page_style}>
                            <Pagination
                              activePage={pagination.activePage}
                              itemsCountPerPage={pagination.pageSize}
                              totalItemsCount={pagination.totalItem}
                              pageRangeDisplayed={pagination.pageRange}
                              onChange={handlePageChange}
                            />
                          </ul>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
              ) : (
                <NoResult />
              )
            ) : (
              LoadingSpinner()
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(AhbabFromContactForm);
