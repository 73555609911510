import axios from "axios";

import { encryptStorage } from "../../constants/EncryptStorage";
import { BASEURL } from "../../constants/const";

export function ApiAhbab() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const fileAuthAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    responseType: "arraybuffer",
  });

  const url = BASEURL + "ahbab/";
  return {
    Add: (data) => authAxios.put(url + "Add/", data),
    Edit: (id) => authAxios.get(url + "Edit/" + id),
    Update: (id, record) => authAxios.put(url + "Edit/" + id, record),
    Delete: (id) => authAxios.delete(url + "Delete/" + id),
    GetSimilarAhbab: (phoneNo = "none") =>
      authAxios.get(url + "GetSimilarAhbab/" + phoneNo),
    GetCountries: () => authAxios.get(url + "GetCountries/"),
    GetStates: (countryId) => authAxios.get(url + "GetStates/" + countryId),
    GetCities: (stateId) => authAxios.get(url + "GetCities/" + stateId),
    GetContactFormAhbab: (searchAhbabModel) =>
      authAxios.post(url + "GetContactFormAhbab/", searchAhbabModel),
    GetAhbabExcelReport: (searchAhbabModel) =>
      fileAuthAxios.post(url + "GetAhbabExcelReport/", searchAhbabModel),
    TransferHabib: (record) => authAxios.post(url + "TransferHabib/", record),
    TransferLocalAhbab: (record) => authAxios.post(url + "TransferLocalAhbab/", record),
    MoveHabibDataSave: (record) =>
      authAxios.put(url + "MoveHabibDataSave/", record),
    GetAhbab: (searchAhbabModel) =>
      authAxios.post(url + "getAhbab/", searchAhbabModel),
    CancelHabib: (id) => authAxios.put(url + "CancelHabib/" + id),
  };
}
