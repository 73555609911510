import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import Select from "react-select";
import {
  Button,
  ButtonGroup,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import swal from "sweetalert";

import Draggable from "react-draggable";
import {
  CheckPermission,
  DateOfBirthValidater,
  LoadingSpinner,
  convertToDateString,
  isUrdu,
} from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { GetLanguageString, NoResult } from "../helper/Components";
import { GetSelectedLanguage } from "../helper/Method";
import { addMember, setupApi } from "../member/ApiMember";
import SelectMemberUnit from "../member/SelectMemberUnit";
import TableView from "../shared/SimilarRecordTable";
import useForm from "../shared/useForm";

const AddAhbabModel = ({
  setCallAddAhbabModel,
  setRenderParticipants,
  courseId = 0,
  eventId = 0,
  setUpdatedList,
  setNewParticipants,
}) => {
  const [selectedDateOfBirth, setSelectedDateOfBirth] = useState(null);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const memberValues = {
    unit: {
      value: loggedInUnitId === 0 ? 1 : loggedInUnitId,
      label: "",
      unitTypeName: loggedInUnitId === 0 ? "Markaz" : "",
    },
    serialNumber: 0,
    birthDate: null,
    cnic: "",
    fatherName: "",
    fullName: "",
    referenceNote: "",
    proceedRequest: false,
    isHabibRequest: true,
    phone: "",
    comments: "",
    status: false,
    courseId: courseId,
    eventId: eventId,
    address: {
      address: "",
      country: "",
      state: "",
      city: "",
    },
  };
  const [completeColor, setCompleteColor] = useState("white");
  const [inCompleteColor, setInCompleteColor] = useState("primary");
  const [status, setStatus] = useState(false);

  const { t } = useTranslation();
  const toggle = () => setCallAddAhbabModel(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loadingRecords, setLoadingRecords] = useState(false);

  const [exactMatchRecords, setExactMatchRecords] = useState(null);
  const [similarMatchRecords, setSimilarMatchRecords] = useState(null);
  const [cnicMatchRecords, setCnicMatchRecords] = useState(null);
  const [phoneMatchRecords, setPhoneMatchRecords] = useState(null);

  const [showSimilarRecords, setShowSimilarRecords] = useState(false);
  const [similarRecords, setSimilarRecords] = useState({
    cnic: "",
    fullName: "",
    fatherName: "",
    phone: "",
  });
  const { errors, setErrors, values, setValues } = useForm(memberValues);

  const selectedLanguage = GetSelectedLanguage();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState();
  const [selectedStateId, setSelectedStateId] = useState();
  const [countriesLoading, setCountriesLoading] = useState(false);

  useEffect(() => {
    setCountriesLoading(true);
    setupApi()
      .GetCountries()
      .then((res) => {
        setCountries(res.data.countries);
        setSelectedCountryId(res.data.selectedCountry.value);
        setValues({
          ...values,
          address: {
            ...values.address,
            country: res.data.selectedCountry,
          },
        });
        if (values.state === "" || values.city === "") {
          setStates([]);
          setCities([]);
          setSelectedStateId(0);
        }
        setCountriesLoading(false);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setupApi()
      .GetStates(selectedCountryId)
      .then((res) => {
        setStates(res.data);
        if (res.data.length === 0) {
          setCities([]);
        }
      })
      .catch((err) => console.log(err));
  }, [selectedCountryId]);

  useEffect(() => {
    setupApi()
      .GetCities(selectedStateId)
      .then((res) => {
        setCities(res.data);
      })
      .catch((err) => console.log(err));
  }, [selectedStateId]);

  const handleOnChangeCountry = (country) => {
    setValues({
      ...values,
      address: {
        ...values.address,
        country: country,
        city: "",
        state: "",
      },
    });
    setCities([]);
    setSelectedStateId(0);
    setSelectedCountryId(country.value);
  };

  const handleOnChangeCity = (city) => {
    setValues({
      ...values,
      address: {
        ...values.address,
        city: city,
      },
    });
  };
  const handleOnChangeState = (state) => {
    setValues({
      ...values,
      address: {
        ...values.address,
        state: state,
        city: "",
      },
    });
    setSelectedStateId(state.value);
  };

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const handleOnClick = (e) => {
    const { value } = e.target;
    if (value === "1") {
      setStatus(true);
      setCompleteColor("primary");
      setInCompleteColor("white");
    } else if (value === "0") {
      setStatus(false);
      setInCompleteColor("primary");
      setCompleteColor("white");
    }
  };

  const validate = () => {
    let temp = {};
    temp.phone =
      values.phone !== "" ? (
        ""
      ) : (
        <GetLanguageString props="member_phone_error_message" />
      );
    temp.fullName =
      values.fullName !== "" ? (
        ""
      ) : (
        <GetLanguageString props="member_first_name_error_message" />
      );
    temp.fatherName =
      values.fatherName !== "" ? (
        ""
      ) : (
        <GetLanguageString props="member_father_name_error_message" />
      );
    temp.cnic =
      values.cnic !== ""
        ? values.cnic.length === 13
          ? ""
          : "CNIC is not valid. It must be 13 numbers."
        : "";

    temp.birthDate =
      selectedDateOfBirth !== null ? (
        DateOfBirthValidater(selectedDateOfBirth) ? (
          ""
        ) : (
          <GetLanguageString props="ahbab_birth_date_less_error_message" />
        )
      ) : (
        "Birth date is required."
      );

    temp.unitError =
      values.unit.unitTypeName !== "Markaz" &&
      values.unit.unitTypeName !== "Zone" &&
      values.unit.unitTypeName !== "ZoneOS" ? (
        ""
      ) : (
        <GetLanguageString props="member_unit_check_error" />
      );
    temp.country =
      Object.keys(values.address?.country).length === 0 ? (
        <GetLanguageString props="member_member_details_country_error_message" />
      ) : (
        ""
      );

    temp.state =
      Object.keys(values.address?.state).length === 0 && states.length !== 0 ? (
        <GetLanguageString props="member_member_details_state_error_message" />
      ) : (
        ""
      );

    temp.city =
      Object.keys(values.address?.city).length === 0 && cities.length !== 0 ? (
        <GetLanguageString props="member_member_details_city_error_message" />
      ) : (
        ""
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    if (!Object.values(similarRecords).every((x) => x === "")) {
      const timeoutId = setTimeout(() => {
        setShowSimilarRecords(true);
        setLoadingRecords(true);
        addMember()
          .GetMemberSimilarRecords(similarRecords)
          .then((res) => {
            setPhoneMatchRecords(res.data.phoneRecords);
            setCnicMatchRecords(res.data.cnicRecords);
            setExactMatchRecords(res.data.exactMatchRecords);
            setSimilarMatchRecords(res.data.similarRecords);
            setLoadingRecords(false);
            if (
              Array.isArray(res.data.exactMatchRecords) &&
              res.data.exactMatchRecords.length === 0 &&
              Array.isArray(res.data.similarRecords) &&
              res.data.similarRecords.length === 0 &&
              (res.data.cnicRecords === null ||
                (Array.isArray(res.data.cnicRecords) &&
                  res.data.cnicRecords.length === 0)) &&
              (res.data.phoneRecords === null ||
                (Array.isArray(res.data.phoneRecords) &&
                  res.data.phoneRecords.length === 0))
            ) {
              setShowSimilarRecords(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoadingRecords(false);
          });
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [similarRecords]);

  useEffect(() => {
    setErrors({
      fatherName: "",
    });
  }, [values, setErrors]);

  const handleInputChangeCnic = (e) => {
    const { value } = e.target;
    setValues({
      ...values,
      cnic: value ? value.replace(/-/g, "") : "",
    });

    if (!CheckPermission("CanRequestAddMember")) {
      setSimilarRecords({
        ...similarRecords,
        cnic: value.replace(/-/g, ""),
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (!CheckPermission("CanRequestAddMember")) {
      if (name === "fullName" || name === "fatherName" || name === "phone") {
        setSimilarRecords({
          ...similarRecords,
          [name]: value,
        });
      }
    }
  };
  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      address: {
        ...values.address,
        [name]: value,
      },
    });
  };

  const handleInputChangeBirthDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setSelectedDateOfBirth(value);
      } else {
        setErrors({
          ...errors,
          birthDate: "The Birth date must not be greater than current date.",
        });
      }
    } else {
      setValues({
        ...values,
        birthDate: null,
      });
      setSelectedDateOfBirth(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const performSubmit = () => {
      // Function declaration to call handleSubmit again
      if (validate()) {
        values.status = status;
        values.birthDate = convertToDateString(selectedDateOfBirth);
        setLoading(true);
        setButtonDisable(true);
        addMember()
          .setMember(values)
          .then((res) => {
            if (!res.data.isSaved) {
              setLoading(false);
              setButtonDisable(false);
              swal({
                title: t(res.data.errorMessage),
                icon: "error",
                buttons: "OK",
              });
            } else if (!res.data.isRequest) {
              setLoading(false);
              setButtonDisable(false);
              swal({
                title: t("ahbab_added_success_message"),
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                icon: "success",
                buttons: "OK",
              });
              if (eventId !== 0) {
                const newParticipant = {
                  memberId: res.data.value,
                  eventId: eventId,
                  name: values.fullName,
                  memberType: res.data.memberType,
                  memberRegNo: res.data.memberRegNo,
                  memberUnitName: res.data.memberUnitName,
                  attendanceStatus: 1,
                  onTime: true,
                };

                setNewParticipants((prevParticipants) => [
                  newParticipant,
                  ...prevParticipants,
                ]);
                setUpdatedList((prevList) => [newParticipant, ...prevList]);
              }
              if (courseId !== 0)
                setRenderParticipants((presValue) => !presValue);
              toggle();
            }
          })
          .catch((err) => {
            setLoading(false);
            setButtonDisable(false);
            swal({
              title: err,
              icon: "error",
              buttons: "OK",
            });
            toggle();
            console.log(err);
          });
      }
    };

    // Call the performSubmit function
    performSubmit();
  };

  return (
    <div>
      {
        <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
          <Modal size="xl" isOpen={true} className="inmodal" autoFocus={false}>
            <ModalHeader className="modal-title" tag="h4">
              {<GetLanguageString props="ahbab_add" />}
            </ModalHeader>
            <ModalBody>
              {!loading ? (
                <div className="ibox ">
                  <div className="ibox-content">
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label className="col-form-label">
                            {<GetLanguageString props="member_first_name" />}
                          </Label>
                          <Input
                            className={
                              isUrdu(values.fullName) ? "ur no-drag" : "no-drag"
                            }
                            autoFocus
                            type="text"
                            name="fullName"
                            value={values.fullName}
                            onChange={handleInputChange}
                            {...(errors.fullName && { invalid: true })}
                          />
                          {errors.fullName && (
                            <FormFeedback>
                              <h4>{errors.fullName}</h4>
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label className="col-form-label">
                            {<GetLanguageString props="member_father_name" />}
                          </Label>
                          <Input
                            className={
                              isUrdu(values.fatherName)
                                ? "ur no-drag"
                                : "no-drag"
                            }
                            type="text"
                            name="fatherName"
                            value={values.fatherName}
                            onChange={handleInputChange}
                            {...(errors.fatherName && { invalid: true })}
                          />
                          {errors.fatherName && (
                            <FormFeedback>
                              <h4>{errors.fatherName}</h4>
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label className="col-form-label">
                            {<GetLanguageString props="member_cnic" />}
                          </Label>
                          <InputMask
                            mask="99999-9999999-9"
                            value={values.cnic}
                            onChange={handleInputChangeCnic}
                            name="cnic"
                            maskChar=""
                          >
                            {(inputProps) => (
                              <Input
                                {...inputProps}
                                type="text"
                                className="no-drag"
                                placeholder="______-_______-_"
                              />
                            )}
                          </InputMask>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label className="col-form-label">
                            {<GetLanguageString props="member_phone" />}
                          </Label>
                          <Input
                            type="text"
                            className="no-drag"
                            name="phone"
                            value={values.phone}
                            onChange={handleInputChange}
                            {...(errors.phone && { invalid: true })}
                          />
                          {errors.phone && (
                            <FormFeedback>
                              <h4>{errors.phone}</h4>
                            </FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label className="col-form-label">
                            {<GetLanguageString props="member_date_of_birth" />}
                          </Label>
                          <DatePicker
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="dd/mm/yyyy"
                            className="form-control no-drag"
                            selected={selectedDateOfBirth}
                            maxDate={new Date()}
                            onChange={handleInputChangeBirthDate}
                            dateFormat="dd/MM/yyyy"
                          />
                          {errors.birthDate && (
                            <h4 className="text-error">{errors.birthDate}</h4>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label className="col-form-label">
                            {
                              <GetLanguageString props="member_reference_note" />
                            }
                          </Label>
                          <Input
                            type="text"
                            name="referenceNote"
                            className={
                              isUrdu(values.referenceNote)
                                ? "ur no-drag"
                                : "no-drag"
                            }
                            value={values.referenceNote}
                            onChange={handleInputChange}
                            {...(errors.referenceNote && { invalid: true })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {" "}
                      <Col lg="6">
                        <FormGroup>
                          <Label className="col-form-label">
                            {<GetLanguageString props="ahbab_address" />}
                          </Label>
                          <Input
                            className={
                              isUrdu(values.address?.address)
                                ? "ur no-drag"
                                : "no-drag"
                            }
                            type="text"
                            name="address"
                            value={values.address?.address}
                            onChange={handleAddressChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        {!countriesLoading ? (
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <Label className="col-form-label">
                                  {<GetLanguageString props="member_country" />}
                                </Label>
                                <Select
                                  options={countries}
                                  value={values.address?.country}
                                  className="basic-single"
                                  placeholder={
                                    selectedLanguage === "en"
                                      ? "Select"
                                      : "منتخب کریں"
                                  }
                                  classNamePrefix="select"
                                  onChange={(country) => {
                                    handleOnChangeCountry(country);
                                  }}
                                />
                                {errors.country && (
                                  <h4 className="text-error">
                                    {errors.country}
                                  </h4>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              {states.length === 0 ? (
                                <h3 className="text-center m-5">{" - "}</h3>
                              ) : (
                                <FormGroup>
                                  <Label className="col-form-label">
                                    {
                                      <GetLanguageString props="ahbeb_state_province" />
                                    }
                                  </Label>
                                  <Select
                                    options={states}
                                    value={values.address?.state}
                                    className="basic-single"
                                    placeholder={
                                      states.length === 0
                                        ? selectedLanguage === "en"
                                          ? "No States Available"
                                          : "کوئی صوبہ موجود نہی ہے"
                                        : selectedLanguage === "en"
                                        ? "Select"
                                        : "منتخب کریں"
                                    }
                                    classNamePrefix="select"
                                    onChange={(state) => {
                                      handleOnChangeState(state);
                                    }}
                                  />
                                  {errors.state && (
                                    <h4 className="text-error">
                                      {errors.state}
                                    </h4>
                                  )}
                                </FormGroup>
                              )}
                            </Col>
                            <Col md="4">
                              {cities.length === 0 ? (
                                <h3 className="text-center m-5">{" - "}</h3>
                              ) : (
                                <FormGroup>
                                  <Label className="col-form-label">
                                    {<GetLanguageString props="member_city" />}
                                  </Label>
                                  <Select
                                    options={cities}
                                    value={values.address?.city}
                                    className="basic-single"
                                    placeholder={
                                      cities.length === 0
                                        ? selectedLanguage === "en"
                                          ? "No Cities Available"
                                          : "کوئی شہر موجود نہی ہے"
                                        : selectedLanguage === "en"
                                        ? "Select"
                                        : "منتخب کریں"
                                    }
                                    classNamePrefix="select"
                                    onChange={(city) => {
                                      handleOnChangeCity(city);
                                    }}
                                  />
                                  {errors.city && (
                                    <h4 className="text-error">
                                      {errors.city}
                                    </h4>
                                  )}
                                </FormGroup>
                              )}
                            </Col>
                          </Row>
                        ) : (
                          LoadingSpinner()
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <SelectMemberUnit
                        values={values}
                        setValues={setValues}
                        unitId={values.unit.value}
                        showExcludeUnit={false}
                      />
                      {errors.unitError && (
                        <h4 className="text-error">{errors.unitError}</h4>
                      )}
                    </Row>
                  </div>
                  {courseId !== 0 ? (
                    <>
                      <br></br>
                      <div className="ibox-content">
                        <Row className="m-t-lg">
                          <Col md="6">
                            <Label>
                              <b>
                                {
                                  <GetLanguageString props="courses_participants_status" />
                                }
                              </b>
                            </Label>
                          </Col>
                          <Col md="6">
                            <b>
                              <Label>
                                {
                                  <GetLanguageString props="courses_participants_comments" />
                                }
                              </Label>
                            </b>
                          </Col>
                        </Row>
                        <Row className="m-t">
                          <Col md="6">
                            <ButtonGroup>
                              <Button
                                color={completeColor}
                                size="sm"
                                onClick={handleOnClick}
                                value="1"
                              >
                                {
                                  <GetLanguageString props="courses_status_completed" />
                                }
                              </Button>
                              <Button
                                color={inCompleteColor}
                                size="sm"
                                onClick={handleOnClick}
                                value="0"
                              >
                                {
                                  <GetLanguageString props="courses_status_incompleted" />
                                }
                              </Button>
                            </ButtonGroup>
                          </Col>
                          <Col md="6">
                            <Input
                              className="ur no-drag"
                              type="text"
                              name="comments"
                              value={values.comments}
                              onChange={handleInputChange}
                            />
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : null}
                  {/* {!CheckPermission("CanRequestAddMember") ? ( */}
                  {showSimilarRecords ? (
                    !loadingRecords ? (
                      <>
                        <br></br>
                        <div className="ibox">
                          <div className="ibox-title">
                            <h5>
                              {
                                <GetLanguageString props="notifications_see_changes_similar_records_exact" />
                              }
                            </h5>
                          </div>

                          <div
                            className="ibox-content no-drag"
                            style={{
                              maxHeight: 300,
                              overflowY: "auto",
                            }}
                          >
                            {" "}
                            {exactMatchRecords?.length !== 0 &&
                            exactMatchRecords !== null ? (
                              <TableView data={exactMatchRecords} />
                            ) : (
                              <NoResult />
                            )}
                          </div>
                        </div>
                        <br></br>
                        <div className="ibox">
                          <div className="ibox-title">
                            <h5>
                              {
                                <GetLanguageString props="notifications_see_changes_similar_records_similar" />
                              }
                            </h5>
                          </div>
                          <div
                            className="ibox-content no-drag"
                            style={{
                              maxHeight: 300,
                              overflowY: "auto",
                            }}
                          >
                            {similarMatchRecords?.length !== 0 &&
                            similarMatchRecords !== null ? (
                              <TableView data={similarMatchRecords} />
                            ) : (
                              <NoResult />
                            )}
                          </div>
                        </div>
                        <br></br>

                        <div className="ibox">
                          <div className="ibox-title">
                            <h5>
                              {
                                <GetLanguageString props="notifications_see_changes_similar_records_phone" />
                              }
                            </h5>
                          </div>
                          <div
                            className="ibox-content no-drag"
                            style={{
                              maxHeight: 300,
                              overflowY: "auto",
                            }}
                          >
                            {phoneMatchRecords?.length !== 0 &&
                            phoneMatchRecords !== null ? (
                              <TableView data={phoneMatchRecords} />
                            ) : (
                              <NoResult />
                            )}
                          </div>
                        </div>
                        <br></br>
                        <div className="ibox">
                          <div className="ibox-title">
                            <h5>
                              {
                                <GetLanguageString props="notifications_see_changes_similar_records_cnic" />
                              }
                            </h5>
                          </div>
                          <div
                            className="ibox-content no-drag"
                            style={{
                              maxHeight: 300,
                              overflowY: "auto",
                            }}
                          >
                            {cnicMatchRecords?.length !== 0 &&
                            cnicMatchRecords !== null ? (
                              <TableView data={cnicMatchRecords} />
                            ) : (
                              <NoResult />
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      LoadingSpinner()
                    )
                  ) : null}
                </div>
              ) : (
                LoadingSpinner()
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button
                disabled={buttonDisable}
                color="primary"
                type="submit"
                onClick={handleSubmit}
              >
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Modal>
        </Draggable>
      }
    </div>
  );
};

export default AddAhbabModel;
