import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  Table,
} from "reactstrap";
import { LoadingSpinner } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { addMember } from "../../member/ApiMember";
import Draggable from "react-draggable";
import swal from "sweetalert";
import { GetSelectedLanguage } from "../../helper/Method";
import { useTranslation } from "react-i18next";

const SelectAssociatedMember = ({
  memberHabibId = 0,
  habibUnitId,
  setShowAssociatedMemberModal,
  setRefreshList,
  refreshList,
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [associatedMemberId, setAssociatedMemberId] = useState(null);
  const [error, setError] = useState("");
  const [reportSearchMemberModel, setReportSearchMemberModel] = useState({
    unitId: habibUnitId,
    loggedInUnitId: loggedInUnitId,
    name: "",
    fatherName: "",
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const { t } = useTranslation();

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const toggle = () => setShowAssociatedMemberModal(false);

  useEffect(() => {
    if (reportSearchMemberModel.name || reportSearchMemberModel.fatherName) {
      const timeoutId = setTimeout(() => {
        setLoading(true);
        addMember()
          .GetAssociatedMembersList(reportSearchMemberModel)
          .then((res) => {
            setMembers(res.data);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            swal({
              title: err,
              icon: "error",
              buttons: "OK",
            });
          });
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [reportSearchMemberModel]);

  const validate = () => {
    if (associatedMemberId === 0) return false;
    else return true;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReportSearchMemberModel({
      ...reportSearchMemberModel,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addMember()
        .AddAssociatedMember(memberHabibId, associatedMemberId)
        .then((res) => {
          swal({
            title: t(res.data),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: "OK",
          });
          setRefreshList(!refreshList);
          toggle();
        })
        .catch((err) =>
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          })
        );
    } else {
      setError("member");
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="ahbab_select_associated_member" />}
          </ModalHeader>
          <Form>
            <ModalBody>
              <Form>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_search_by_name" />}
                      </Label>
                      <Input
                        className="ur no-drag"
                        type="text"
                        name="name"
                        value={reportSearchMemberModel.name}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="col-form-label">
                        {
                          <GetLanguageString props="member_search_by_father_name" />
                        }
                      </Label>
                      <Input
                        className="ur no-drag"
                        type="text"
                        name="fatherName"
                        value={reportSearchMemberModel.fatherName}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
              {!loading ? (
                <div className="ibox">
                  <div className="ibox-title">
                    {<GetLanguageString props="member_members" />}
                  </div>
                  <div className="ibox-content">
                    {members.length > 0 ? (
                      <div className="table-responsive">
                        <Table className="table-bordered">
                          <thead>
                            <tr>
                              <th>
                                {<GetLanguageString props="member_select" />}
                              </th>
                              <th>#</th>
                              <th>
                                <GetLanguageString props="member_reg_number" />
                              </th>
                              <th>
                                <GetLanguageString props="common_name" />
                              </th>
                              <th>
                                <GetLanguageString props="member_father_name" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {members?.map((record, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <Input
                                      id={"select"}
                                      type="radio"
                                      name="member"
                                      className="big-checkbox"
                                      onChange={() => {
                                        setAssociatedMemberId(record.id);
                                        setError("");
                                      }}
                                    />
                                  </td>
                                  <td>{index + 1}</td>
                                  <td>{record.regNo}</td>
                                  <td className="ur no-drag">
                                    {record.fullName}
                                  </td>
                                  <td className="ur no-drag">
                                    {record.fatherName}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    ) : (
                      <NoResult />
                    )}
                  </div>
                </div>
              ) : (
                LoadingSpinner()
              )}
              {error && (
                <h4 className="text-center no-results">
                  {<GetLanguageString props="member_select_error" />}
                </h4>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button color="primary" type="submit" onClick={handleSubmit}>
                {<GetLanguageString props="common_save_changes" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default SelectAssociatedMember;
