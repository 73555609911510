import React from "react";
import { Table } from "reactstrap";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { isUrdu } from "../../../constants/const";
import { IoLogoWhatsapp } from "react-icons/io";

const MemberContactDetails = ({ tab11, memberContactDetails }) => {
  return (
    <div role="tabpanel" id="tab-11" className={"tab-pane " + tab11}>
      <div className="panel-body">
        <div className="table-responsive">
          {memberContactDetails?.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{<GetLanguageString props="member_contact_type" />}</th>
                  <th>{<GetLanguageString props="common_value" />}</th>
                </tr>
              </thead>
              <tbody>
                {memberContactDetails.map((record, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className={isUrdu(record.contactType) ? "ur" : "en"}>
                        {record.contactType}{" "}
                        {record.isWhatsApp ? (
                          <IoLogoWhatsapp
                            size={27}
                            color={"#128C7E"}
                            style={{
                              paddingLeft: 10,
                              fontSize: 18,
                            }}
                          />
                        ) : null}
                      </td>
                      <td>{record.value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <NoResult />
          )}
        </div>
      </div>
    </div>
  );
};

export default MemberContactDetails;
