import { t } from "i18next";
import React, { useState } from "react";
import Draggable from "react-draggable";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import swal from "sweetalert";
import { isUrdu, LoadingSpinner } from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import { GetSelectedLanguage } from "../../helper/Method";
import { ApiDocumentTag } from "./ApiDocumentTag";

const AddDocumentTag = ({ setAddModal, refresh, setRefresh }) => {
  const [tagName, setTagName] = useState("");
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const validate = () => {
    let temp = {};
    temp.tagName = tagName ? (
      ""
    ) : (
      <GetLanguageString props="common_field_required_error" />
    );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTagName(value);
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      setButtonDisable(true);
      ApiDocumentTag()
        .AddDocumentTag({ label: tagName, value: 0 })
        .then(() => {
          swal({
            title: t("document_add_success_document_tags"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
          });
          setRefresh(!refresh);
          setAddModal(false);
          setLoading(false);
          setButtonDisable(false);
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          setButtonDisable(false);
        });
    }
  };

  return (
    <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
      <Modal isOpen={true} className="inmodal" autoFocus={false}>
        <ModalHeader className="modal-title" tag="h4">
          <GetLanguageString props="document_add_document_tags" />
        </ModalHeader>
        {!loading ? (
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              <FormGroup>
                <Label>
                  <GetLanguageString props="document_document_tag" />
                </Label>
                <Input
                  autoFocus
                  type="text"
                  name="tagName"
                  className={isUrdu(tagName) ? "no-drag ur" : "no-drag"}
                  value={tagName}
                  onChange={handleInputChange}
                  {...(errors?.tagName && { invalid: true })}
                />
                {errors?.tagName && (
                  <FormFeedback>
                    <h4>{errors?.tagName}</h4>
                  </FormFeedback>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={() => setAddModal(false)}>
                <GetLanguageString props="common_cancel" />
              </Button>
              <Button color="primary" type="submit" disabled={buttonDisable}>
                <GetLanguageString props="common_save" />
              </Button>{" "}
            </ModalFooter>
          </Form>
        ) : (
          LoadingSpinner()
        )}
      </Modal>
    </Draggable>
  );
};

export default AddDocumentTag;
