import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { FaSquareMinus, FaSquarePlus } from "react-icons/fa6";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import swal from "sweetalert";
import {
  LoadingSpinner,
  isUrdu,
  removeDuplicates,
} from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";
import { ApiDocumentType } from "../config/documentType/ApiDocumentType";
import { GetLanguageString } from "../helper/Components";
import { GetSelectedLanguage } from "../helper/Method";
import { ApiDocuments } from "./ApiDocuments";
import Permissions from "./PermissionsComponent";
import CreateableTags from "./CreateableTags";
import { toast } from "react-toastify";

const UploadFile = ({
  setUploadFileModal,
  folderId,
  documents,
  setDocuments,
  droppedFile,
  setDroppedFile,
  uploadRefreshDocuments,
  setUploadInProgress,
}) => {
  const toggle = () => {
    setUploadFileModal(false);
    setDroppedFile(null);
  };
  const [loading, setLoading] = useState(false);
  const [documentsTypesDropdown, setDocumentsTypesDropdown] = useState([]);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [filePermissions, setFilePermissions] = useState(0);
  const [inheritFolderPermissions, setInheritFolderPermissions] = useState(2);
  const [isLink, setIsLink] = useState(1);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState(
    droppedFile !== null ? droppedFile : ""
  );
  const [uploadFileValues, setUploadFileValues] = useState({
    uploadedFiles: droppedFile !== null ? droppedFile : "",
    description: "",
    documentType: "",
    permissionsApplied: filePermissions === 1,
    inheritPermissionsFromParentFolder: inheritFolderPermissions === 2,
    permissions: [],
    tags: [],
    isLink: false,
    fileName: "",
    fileLink: "",
  });

  if (folderId === 0) folderId = 1;

  const [permissions, setPermissions] = useState([
    {
      unit: { value: loggedInUnitId, label: "" },
      forChildUnits: false,
      responsibilities: [],
      selectUnit: true,
      selectResponsibilities: false,
      selectAllResponsibilities: false,
    },
  ]);

  const addPermission = () => {
    const tempValues = [
      {
        unit: { value: 0, label: "" },
        forChildUnits: false,
        responsibilities: [],
        selectUnit: true,
        selectResponsibilities: false,
        selectAllResponsibilities: false,
      },
      ...permissions,
    ];
    setPermissions(tempValues);
  };

  const removeObject = (id) => {
    let tempValues = [...permissions];
    tempValues.splice(id, 1);
    setPermissions(tempValues);
  };
  const handleFilePermissions = (e) => {
    const { value } = e.target;
    setFilePermissions(parseInt(value));
    setInheritFolderPermissions(0);
    setUploadFileValues({
      ...uploadFileValues,
      permissionsApplied: value === 1,
      inheritPermissionsFromParentFolder: false,
    });
  };
  const handleInheritFolderPermissions = (e) => {
    const { value } = e.target;
    setInheritFolderPermissions(parseInt(value));
    setFilePermissions(0);
    setUploadFileValues({
      ...uploadFileValues,
      inheritPermissionsFromParentFolder: value === 2,
      permissionsApplied: false,
    });
  };

  const handleIsLink = (e) => {
    const { value } = e.target;
    let val = parseInt(value);
    setIsLink(val);
    setUploadFileValues({
      ...uploadFileValues,
      isLink: val === 2,
    });
  };

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const memberName = encryptStorage.getItem("memberName");
  const unitName = encryptStorage.getItem("loggedInUnitName");
  const responsibiityName = encryptStorage.getItem("responsibilityName");

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const [errors, setErrors] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    ApiDocumentType()
      .getDocumentTypesForDropDowns()
      .then((res) => {
        setDocumentsTypesDropdown(res.data);
        setLoading(false);
      })
      .catch((err) => {
        swal({
          title: err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
        console.log(err.response?.data || err);
      });
  }, []);

  const handleUpload = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to Array
    const newFiles = [];
    const fileErrors = [];

    files.forEach((file) => {
      // Size check (25 MB in bytes)
      if (file.size > 26214400) {
        fileErrors.push(
          t("file_size_limit_exceed_error") + " ( " + file.name + " ) "
        );
      }
      // File type check (no executable files)
      const fileType = file.type || file.name.split(".").pop().toLowerCase();
      if (
        fileType === "exe" ||
        fileType === "msi" ||
        fileType === "application/x-msdownload"
      ) {
        fileErrors.push(t("file_type_error") + "( " + file.name + " ) ");
      }

      // Add valid files to the newFiles array
      if (
        file.size <= 26214400 &&
        !["exe", "msi", "application/x-msdownload"].includes(fileType)
      ) {
        newFiles.push(file);
      }
    });

    // Set error messages if any, otherwise clear errors
    setErrors({
      ...errors,
      uploadedFiles: fileErrors.length ? fileErrors.join(", ") : "",
    });

    // Only set the new files if there are no errors
    if (fileErrors.length === 0) {
      setUploadedFiles(newFiles);
    }
  };

  const validate = () => {
    let temp = {};

    if (!uploadFileValues.isLink) {
      if (uploadedFiles.length > 0) {
        const filesErrors = uploadedFiles.map((file, index) => {
          const fileType =
            file?.type || file?.name.split(".").pop().toLowerCase();

          // Check conditions for each file
          if (!uploadFileValues.isLink && !file) {
            return <GetLanguageString props="file_error" />;
          } else if (file.size > 26214400) {
            return <GetLanguageString props="file_size_limit_exceed_error" />;
          } else if (
            fileType === "exe" ||
            fileType === "msi" ||
            fileType === "application/x-msdownload"
          ) {
            return <GetLanguageString props="file_type_error" />;
          } else {
            return ""; // No error
          }
        });
        temp.uploadedFiles = filesErrors.some((error) => error !== "")
          ? filesErrors
          : "";
      }
      temp.uploadedFiles =
        uploadedFiles.length === 0 ? (
          <GetLanguageString props="file_error" />
        ) : (
          ""
        );
    }
    // Check if any file has an error
    if (uploadFileValues.isLink) {
      temp.fileName = uploadFileValues.fileName ? (
        ""
      ) : (
        <GetLanguageString props="document_upload_file_file_name_error" />
      );
      temp.fileLink = uploadFileValues.fileLink ? (
        ""
      ) : (
        <GetLanguageString props="document_upload_file_file_link_error" />
      );
    }
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleChangeDocumentType = (record) => {
    setUploadFileValues({
      ...uploadFileValues,
      documentType: record,
    });

    setErrors({
      ...errors,
      documentType: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUploadFileValues({
      ...uploadFileValues,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setLoading(true);
    setButtonDisable(true);
    setUploadInProgress(true);

    const toastId = toast.loading(t("document_upload_file_toast"), {
      position: toast.POSITION.BOTTOM_LEFT,
      style: { backgroundColor: "#40576d", color: "#DFE4ED" },
    });

    const tempValues = [...documents];
    const date = new Date();

    // Add link or files to tempValues
    if (uploadFileValues.isLink) {
      tempValues.push(createDocumentObject(uploadFileValues, date, "link"));
    } else {
      uploadedFiles.forEach((file, index) => {
        tempValues.push(
          createDocumentObject(uploadFileValues, date, file, index)
        );
      });
    }

    setDocuments(tempValues);

    try {
      if (uploadFileValues.isLink) {
        await handleLinkUpload();
      } else {
        toggle();
        await handleFileUploads(uploadedFiles, tempValues);
      }

      toast.update(toastId, {
        render: uploadFileValues.isLink
          ? t("document_add_link_toast_success")
          : uploadedFiles.length + t("document_upload_file_toast_success"),
        type: "success",
        isLoading: false,
        autoClose: 3000,
        style: { backgroundColor: "#ffffff", color: "grey" },
      });

      setTimeout(uploadRefreshDocuments, 1000);
    } catch (err) {
      showErrorToast(err, toastId);
    } finally {
      setUploadInProgress(false);
      setLoading(false);
      toggle();
    }
  };

  // Helper Functions
  const createDocumentObject = (values, date, file, index = null) => ({
    name: values.isLink && values.fileName ? values.fileName : file.name,
    fileType: values.isLink ? "link" : file.type,
    uploaderName: memberName,
    uploaderResponsibility: responsibiityName,
    uploaderUnit: unitName,
    description: values.description,
    size: values.isLink ? 0 : file.size,
    fileStatus: values.isLink ? "link_added" : "file_in_progress",
    createdDate: date,
    addedIndex: index,
  });

  const handleLinkUpload = async () => {
    const formData = createFormData(uploadFileValues, null);
    await ApiDocuments().addDocument(formData);
    uploadRefreshDocuments();
  };

  const handleFileUploads = async (files, tempValues) => {
    const uploadPromises = files.map((file, index) => {
      const formData = createFormData(
        uploadFileValues,
        file,
        tempValues,
        index
      );
      return ApiDocuments()
        .addDocument(formData)
        .then((res) => {
          const updatedValues = [...tempValues];
          const doc = updatedValues[res.data.index];
          doc.id = res.data.documentId;
          doc.fileUploadBackgroundJobId = res.data.jobId;
          doc.fileStatus = res.data.status;
          setDocuments(updatedValues);
        });
    });

    await Promise.all(uploadPromises);
  };

  const createFormData = (
    values,
    file = null,
    tempValues = [],
    index = null
  ) => {
    const formData = new FormData();
    formData.append("FileType", values.documentType?.value || 0);
    formData.append("Description", values.description || "^");
    formData.append("FileName", values.fileName || "^");
    formData.append("FileLink", values.fileLink || "^");
    formData.append("IsLink", values.isLink);
    formData.append("ParentFolderId", folderId);
    formData.append("PermissionsApplied", filePermissions === 1);
    formData.append(
      "InheritPermissionsFromParentFolder",
      inheritFolderPermissions === 2
    );
    formData.append(
      "Permissions",
      JSON.stringify(removeDuplicates(permissions))
    );
    formData.append("tags", JSON.stringify(selectedTags));

    if (file) formData.append("file", file);
    if (index !== null)
      formData.append(
        "Index",
        tempValues.findIndex((item) => item.addedIndex === index)
      );

    return formData;
  };

  const showErrorToast = (err, toastId) => {
    console.error(err);
    toast.update(toastId, {
      render: t("error_occurred"),
      type: "error",
      isLoading: false,
      autoClose: 3000,
      style: { backgroundColor: "#ffffff", color: "grey" },
    });
    swal({ title: err, icon: "error", buttons: "OK" });
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal
          size={filePermissions ? "lg" : "m"}
          style={{
            maxWidth: filePermissions ? "980px" : "600px",
            width: "100%",
          }}
          isOpen
          className="inmodal"
          autoFocus={false}
        >
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="document_upload_file" />}
          </ModalHeader>
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              {!loading ? (
                <>
                  <Row>
                    {" "}
                    <Row>
                      <Col md="6">
                        <Label check for={"uploadFile"}>
                          <Input
                            type="radio"
                            id="uploadFile"
                            value={1}
                            name="uploadFile"
                            checked={isLink === 1}
                            onChange={handleIsLink}
                          />
                          &nbsp;&nbsp;
                          {
                            <>
                              <GetLanguageString props="member_member_detail_upload_documents" />
                              <span
                                style={{
                                  fontStyle: "italic",
                                  fontSize: 8,
                                  color: "red",
                                  marginLeft: 5,
                                }}
                              >
                                *maximum file size 25 mb
                              </span>
                            </>
                          }
                        </Label>
                      </Col>

                      {uploadedFiles.length === 0 ? (
                        <Col md="6">
                          <Label check for={"isLink"}>
                            <Input
                              type="radio"
                              id="isLink"
                              value={2}
                              name="isLink"
                              checked={isLink === 2}
                              onChange={handleIsLink}
                            />
                            &nbsp;&nbsp;
                            {
                              <GetLanguageString
                                props={"document_upload_file_file_link_add"}
                              />
                            }
                          </Label>
                        </Col>
                      ) : null}
                    </Row>
                    <br></br>
                    <br></br>
                    {isLink === 1 ? (
                      <Col md={"12"}>
                        <FormGroup>
                          {droppedFile !== null ? (
                            <>
                              <Input
                                type="text"
                                value={`${uploadedFiles.length} Files`}
                                id="fileInputTooltip"
                                readOnly
                              />
                              <UncontrolledTooltip
                                target="fileInputTooltip"
                                placement="right"
                              >
                                <ul
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    listStyleType: "none",
                                  }}
                                >
                                  {uploadedFiles.map((file, index) => (
                                    <li
                                      key={index}
                                      style={{ fontSize: "12px" }}
                                    >
                                      {file.name}
                                      <hr></hr>
                                    </li>
                                  ))}
                                </ul>
                              </UncontrolledTooltip>
                            </>
                          ) : (
                            <Input
                              type="file"
                              multiple
                              name="uploadedFiles"
                              onChange={handleUpload}
                              {...(errors?.uploadedFiles && { invalid: true })}
                            />
                          )}
                          {errors?.uploadedFiles && (
                            <div className="text-error">
                              {errors.uploadedFiles}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    ) : (
                      <>
                        <Col md={filePermissions ? "6" : "12"}>
                          <FormGroup>
                            <Label>
                              {
                                <GetLanguageString props="document_upload_file_file_name" />
                              }
                            </Label>
                            <Input
                              type="text"
                              name="fileName"
                              className={
                                isUrdu(uploadFileValues.fileName)
                                  ? "ur no-drag"
                                  : "no-drag"
                              }
                              value={uploadFileValues.fileName}
                              onChange={handleInputChange}
                              {...(errors?.fileName && { invalid: true })}
                            />
                            {errors?.fileName && (
                              <div className="text-error">
                                {errors.fileName}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md={filePermissions ? "6" : "12"}>
                          <FormGroup>
                            <Label>
                              {
                                <GetLanguageString props="document_upload_file_file_link" />
                              }
                            </Label>
                            <Input
                              type="text"
                              name="fileLink"
                              className={
                                isUrdu(uploadFileValues.fileLink)
                                  ? "ur no-drag"
                                  : "no-drag"
                              }
                              value={uploadFileValues.fileLink}
                              onChange={handleInputChange}
                              {...(errors?.fileLink && { invalid: true })}
                            />
                            {errors?.fileLink && (
                              <div className="text-error">
                                {errors.fileLink}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row>
                    <Col md={filePermissions ? "4" : "12"}>
                      <FormGroup>
                        <Label>
                          {" "}
                          {
                            <GetLanguageString props="member_member_detail_select_documents_type" />
                          }
                        </Label>
                        <Select
                          value={uploadFileValues.documentType}
                          options={documentsTypesDropdown}
                          className={
                            GetSelectedLanguage() === "ur"
                              ? "basic-multi-select ur no-drag"
                              : "basic-multi-select no-drag"
                          }
                          classNamePrefix="select"
                          onChange={(record) => {
                            handleChangeDocumentType(record);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={filePermissions ? "4" : "12"}>
                      <FormGroup>
                        <Label for="tag-selector">
                          {<GetLanguageString props="document_document_tags" />}
                        </Label>
                        <CreateableTags
                          selectedTags={selectedTags}
                          setSelectedTags={setSelectedTags}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={filePermissions ? "4" : "12"}>
                      {" "}
                      <FormGroup>
                        <Label>
                          {<GetLanguageString props="common_description" />}
                        </Label>
                        <Input
                          type="text"
                          name="description"
                          className={
                            isUrdu(uploadFileValues.description)
                              ? "ur no-drag"
                              : "no-drag"
                          }
                          value={uploadFileValues.description}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Label check for={"inheritFolderPermissions"}>
                        <Input
                          type="radio"
                          id="inheritFolderPermissions"
                          value={2}
                          name="inheritFolderPermissions"
                          checked={inheritFolderPermissions === 2}
                          onChange={handleInheritFolderPermissions}
                        />
                        &nbsp;&nbsp;
                        {
                          <GetLanguageString
                            props={"folder_document_inherit_permissions"}
                          />
                        }
                      </Label>
                    </Col>
                    <Col md="6">
                      <Label check for={"filePermissions"}>
                        <Input
                          type="radio"
                          id="filePermissions"
                          value={1}
                          name="filePermissions"
                          checked={filePermissions === 1}
                          onChange={handleFilePermissions}
                        />
                        &nbsp;&nbsp;
                        {
                          <GetLanguageString
                            props={"folder_document_apply_permissions"}
                          />
                        }
                      </Label>
                    </Col>
                  </Row>
                  {filePermissions === 1 && (
                    <>
                      <br></br>
                      <div className="ibox-content">
                        <Row>
                          <Col md="11"></Col>
                          <Col md="1" className="text-center">
                            <div>
                              <FaSquarePlus
                                color="green"
                                size={25}
                                style={{ cursor: "pointer" }}
                                onClick={addPermission}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          {permissions?.map((item, index) => {
                            return (
                              <>
                                <div>
                                  <br></br>
                                  <Permissions
                                    item={item}
                                    index={index}
                                    permissions={permissions}
                                    setPermissions={setPermissions}
                                  />
                                  <br></br>
                                </div>
                                {permissions.length !== 1 && (
                                  <Row>
                                    <Col md="11"></Col>
                                    <Col md="1" className="text-center">
                                      <div>
                                        <FaSquareMinus
                                          color="red"
                                          size={25}
                                          style={{ cursor: "pointer" }}
                                          onClick={() => removeObject(index)}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                )}
                                <div>
                                  {index + 1 !== permissions.length && (
                                    <div class="divider div-transparent div-dot"></div>
                                  )}
                                  <br></br>
                                </div>
                              </>
                            );
                          })}
                        </Row>
                      </div>
                    </>
                  )}
                </>
              ) : (
                LoadingSpinner()
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="white" onClick={toggle}>
                {<GetLanguageString props="common_cancel" />}
              </Button>
              <Button color="primary" disabled={buttonDisable} type="submit">
                {<GetLanguageString props="document_upload_file" />}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Draggable>
    </div>
  );
};

export default UploadFile;
