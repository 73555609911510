import React, { useEffect, useState } from "react";
import { Table, Button, ButtonGroup } from "reactstrap";
import swal from "sweetalert";
import { t } from "i18next";
import { ApiDocumentTag } from "./ApiDocumentTag";
import { GetLanguageString, NoResult } from "../../helper/Components";
import {
  applyClassToCharacters,
  LoadingSpinner,
} from "../../../constants/const";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import EditDocumentTag from "./EditDocumentTag";
import { IoAdd } from "react-icons/io5";
import AddDocumentTag from "./AddDocumentTag";

const DocumentTag = ({ activeTab }) => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [tags, setTags] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedTag, setSelectedTag] = useState({});

  const handleDelete = (tagValue) => {
    if (tagValue.isDeletable) {
      swal({
        title: t("common_delete_message"),
        text: t("common_delete_detail_message"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          ApiDocumentTag()
            .DeleteDocumentTag(tagValue.value)
            .then((res) => {
              swal({
                title: t("document_delete_success_document_tags"),
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                icon: "success",
                buttons: GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
              });
              setRefresh(!refresh);
            })
            .catch((err) => {
              swal({ title: err.response.data, icon: "error", buttons: "OK" });
            });
        }
      });
    } else {
      swal({
        title: t("tag_have_documents"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "error",
        buttons: "OK",
      });
    }
  };

  useEffect(() => {
    if (activeTab === "documentTags") {
      setLoading(true);
      ApiDocumentTag()
        .GetDocumentTags()
        .then((res) => {
          setTags(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [refresh, activeTab]);

  const handleEdit = (tag) => {
    setSelectedTag(tag);
    setEditModal(true);
  };

  return (
    <>
      {addModal ? (
        <AddDocumentTag
          setAddModal={setAddModal}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      ) : null}
      {editModal ? (
        <EditDocumentTag
          selectedTag={selectedTag}
          setEditModal={setEditModal}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      ) : null}
      {!loading ? (
        <div className="ibox">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="document_document_tags" />}</h5>
            <Button
              color="white"
              size="sm"
              className={GetCustomStyles().btn_style}
              onClick={() => setAddModal(true)}
            >
              <IoAdd
                size={18}
                color="#18A689"
                style={{ marginRight: 3, marginBottom: 1 }}
              />
              <GetLanguageString props="document_add_document_tags" />
            </Button>
          </div>
          <div className="ibox-content">
            {tags.length !== 0 ? (
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        {<GetLanguageString props="tags" />}
                      </th>
                      <th className="text-center">
                        {<GetLanguageString props="common_action" />}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tags.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td className="p-t-custom">{index + 1}</td>
                          <td>{applyClassToCharacters(record.label, "ur")}</td>
                          <td className="text-center">
                            <ButtonGroup>
                              <Button
                                color="white"
                                size="sm"
                                onClick={() => handleEdit(record)}
                              >
                                <GetLanguageString props="common_edit" />
                              </Button>{" "}
                              <Button
                                color="white"
                                size="sm"
                                onClick={() => handleDelete(record)}
                              >
                                <GetLanguageString props="common_delete" />
                              </Button>{" "}
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <NoResult />
            )}
          </div>
        </div>
      ) : (
        LoadingSpinner()
      )}
    </>
  );
};

export default DocumentTag;
