import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  ButtonGroup,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import DatePicker from "react-datepicker";
import { withRouter, useParams } from "react-router-dom";
import { addMember } from "../ApiMember";
import InputMask from "react-input-mask";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import {
  CheckIsAdmin,
  CheckPermission,
  LoadingSpinner,
  convertUTCToLocalTime,
  DateOfBirthValidater,
  convertToDateString,
  isUrdu,
  applyClassToCharacters,
} from "../../../constants/const";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectPreviousMember from "./SelectPreviousMember";
import { isEqual } from "lodash";

const UpdateMemberInfo = ({
  history,
  tab1,
  memberId,
  addNewRafiq,
  setMemberId,
  setUpdatedDetails,
  updatedDetails,
  memberTypeId,
  activeTab,
  setActiveTab,
  setDiscard,
}) => {
  const notify = (string) => toast.success(string);
  const { t } = useTranslation();
  const { id } = useParams();
  const currentRecordId = parseInt(id);
  const [loading, setLoading] = useState(false);
  const [callApi, setCallApi] = useState(false);
  const [currentRecordRegNo, setCurrentRecordRegNo] = useState("");
  const [previousMemberModal, setPreviousMemberModal] = useState(false);
  const [isPreviousRecord, setIsPreviousRecord] = useState(true);
  const [birthDate, setBirthDate] = useState(null);
  const [joiningDate, setJoiningDate] = useState(null);
  const [originalMemberValues, setOriginalMemberValues] = useState({
    birthDate: null,
    cnic: "",
    fatherName: "",
    fullName: "",
    joiningDate: null,
    bloodGroup: "",
    nativeTown: "",
    referenceNote: "",
    regNo: "",
    previousRecordId: null,
    previousRecordRegNo: "",
    memberTypeId: memberTypeId,
  });
  const [memberValues, setMemberValues] = useState({
    birthDate: null,
    cnic: "",
    fatherName: "",
    fullName: "",
    joiningDate: null,
    bloodGroup: "",
    nativeTown: "",
    referenceNote: "",
    regNo: "",
    previousRecordId: null,
    previousRecordRegNo: "",
    currentRecordId: null,
    memberTypeId: memberTypeId,
  });
  const [errors, setErrors] = useState(memberValues);

  useEffect(() => {
    if (memberValues.currentRecordId !== null && currentRecordId === memberId) {
      setIsPreviousRecord(false);
    } else {
      setIsPreviousRecord(true);
    }
  }, [memberValues.currentRecordId, currentRecordId, memberId]);
  useEffect(() => {
    if (currentRecordId === memberId) setCurrentRecordRegNo(memberValues.regNo);
  }, [memberValues, memberId, currentRecordId]);

  useEffect(() => {
    if (tab1 === "active") {
      setLoading(true);
      addMember()
        .getMemberByIdForDetailData(memberId)
        .then((res) => {
          let values = {
            ...res.data,
            birthDate: res.data.birthDate
              ? new Date(convertUTCToLocalTime(res.data.birthDate))
              : null,
            joiningDate: res.data.joiningDate
              ? new Date(convertUTCToLocalTime(res.data.joiningDate))
              : null,
          };
          setMemberValues(values);
          setOriginalMemberValues(values);
          setJoiningDate(
            res.data.joiningDate
              ? new Date(convertUTCToLocalTime(res.data.joiningDate))
              : null
          );
          setBirthDate(
            res.data.birthDate
              ? new Date(convertUTCToLocalTime(res.data.birthDate))
              : null
          );
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
        });
    }
  }, [memberId, updatedDetails, callApi, tab1]);

  const validate = () => {
    let temp = {};
    temp.fullName = memberValues.fullName ? (
      ""
    ) : (
      <GetLanguageString props="member_first_name_error_message" />
    );
    temp.fatherName = memberValues.fatherName ? (
      ""
    ) : (
      <GetLanguageString props="member_father_name_error_message" />
    );
    if (memberTypeId !== 11) {
      temp.birthDate =
        birthDate !== null ? (
          DateOfBirthValidater(birthDate) ? (
            ""
          ) : (
            <GetLanguageString props="member_birth_date_less_error_message" />
          )
        ) : (
          <GetLanguageString props="member_birth_date_error_message" />
        );
    } else {
      temp.birthDate =
        birthDate !== null ? (
          DateOfBirthValidater(birthDate) ? (
            ""
          ) : (
            <GetLanguageString props="ahbab_birth_date_less_error_message" />
          )
        ) : (
          ""
        );
    }
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMemberValues({
      ...memberValues,
      [name]: value,
    });
    setErrors({
      [name]: "",
    });
  };

  const handleInputChangeBirthDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setBirthDate(value);
      }
    } else {
      setBirthDate(null);
    }
  };

  const handleInputChangeCnic = (e) => {
    const { value } = e.target;
    setMemberValues({
      ...memberValues,
      cnic: value.replace(/-/g, ""),
    });
  };

  const handleInputChangeJoiningDate = (value) => {
    if (value !== null) {
      if (value <= new Date()) {
        setJoiningDate(value);
      }
    } else {
      setJoiningDate(null);
    }
  };

  const deleteMemberPreviousRecord = () => {
    swal({
      title: t("common_delete_message"),
      className: GetSelectedLanguage() === "en" ? "" : "ur",
      text: t("common_delete_detail_message"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        addMember()
          .DeleteMemberPerviousRecord(memberId)
          .then((res) => {
            setCallApi((previousValue) => !previousValue);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            swal({
              title: err,
              icon: "error",
              buttons: "OK",
            });
            setLoading(false);
          });
      }
    });
  };

  const handleCancel = (e) => {
    const isMemberChanged = isEqual(originalMemberValues, memberValues);
    if (!isMemberChanged) {
      swal({
        title: "Discard changes?",
        text: "Do you want to discard changes or save before canceling?",
        icon: "warning",
        buttons: ["Save Changes", "Discard"],
        closeOnClickOutside: false,
        dangerMode: true,
      }).then((willDiscard) => {
        if (willDiscard) {
          history.goBack(); // Process with cancel
        } else {
          handleSubmit(e); // Call the save function when the user chooses not to discard changes
        }
      });
    } else {
      history.goBack(); // No changes, directly go back
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    apiCall();
    setOriginalMemberValues(memberValues);
  };

  const apiCall = () => {
    if (validate()) {
      memberValues.memberTypeId = memberTypeId;
      memberValues.birthDate = convertToDateString(birthDate);
      memberValues.joiningDate = convertToDateString(joiningDate);
      setLoading(true);
      addMember()
        .addMemberInfo(memberId, memberValues, addNewRafiq)
        .then((res) => {
          if (res.data === "") {
            swal({
              title:
                memberTypeId !== 11
                  ? t("member__member_info_added_success_message")
                  : t("member__habib_info_added_success_message"),
              className: GetSelectedLanguage() === "en" ? "" : "ur",
              icon: "success",
              buttons: "OK",
            }).then((ok) => {
              setActiveTab("tab1");
              setDiscard(true);
            });
            setUpdatedDetails((prevState) => !prevState);
          } else notify(res.data);

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          }).then((ok) => {
            setActiveTab("tab1");
          });
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (tab1 === "active") {
      // Check if the active tab has changed
      if (activeTab && activeTab !== "tab1") {
        // Check if there are unsaved changes
        const isMemberChanged = !isEqual(originalMemberValues, memberValues);
        if (isMemberChanged) {
          swal({
            title: "Discard changes?",
            text: "Do you want to discard changes or save before canceling for Member Info?",
            icon: "warning",
            buttons: ["Save Changes", "Discard"],
            closeOnClickOutside: false,
            dangerMode: true,
          }).then((willDiscard) => {
            if (willDiscard) {
              setDiscard(true);
              setOriginalMemberValues("");
              setMemberValues("");
            } else {
              apiCall(); // Call the save function when the user chooses not to discard changes
            }
          });
        } else {
          setDiscard(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div role="tabpanel" id="tab-1" className={"tab-pane " + tab1}>
      {previousMemberModal ? (
        <SelectPreviousMember
          memberId={memberId}
          setPreviousMemberModal={setPreviousMemberModal}
          setCallApi={setCallApi}
        />
      ) : null}
      {!loading ? (
        <Form onSubmit={handleSubmit}>
          <div className="panel-body">
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="member_first_name" />}
                  </Label>
                  <Input
                    className={isUrdu(memberValues.fullName) ? "ur" : ""}
                    type="text"
                    name="fullName"
                    value={memberValues.fullName}
                    onChange={handleInputChange}
                    {...(errors.fullName && { invalid: true })}
                  />
                  {errors.fullName && (
                    <FormFeedback>
                      <h4>{errors.fullName}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="member_reg_number" />}
                  </Label>
                  <Input
                    type="text"
                    name="regNo"
                    value={memberValues.regNo}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="member_father_name" />}
                  </Label>
                  <Input
                    className={isUrdu(memberValues.fatherName) ? "ur" : ""}
                    type="text"
                    name="fatherName"
                    value={memberValues.fatherName}
                    onChange={handleInputChange}
                    {...(errors.fatherName && { invalid: true })}
                  />
                  {errors.fatherName && (
                    <FormFeedback>
                      <h4>{errors.fatherName}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                {memberTypeId !== 11 ? (
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="member_registration_date" />}
                    </Label>
                    <DatePicker
                      className="form-control"
                      selected={joiningDate}
                      maxDate={new Date()}
                      showYearDropdown
                      placeholderText="dd/mm/yyyy"
                      onChange={handleInputChangeJoiningDate}
                      dateFormat="dd/MM/yyyy"
                    />
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="member_date_of_birth" />}
                    </Label>
                    <DatePicker
                      placeholderText="dd/mm/yyyy"
                      className="form-control"
                      selected={birthDate}
                      maxDate={new Date()}
                      showYearDropdown
                      onChange={handleInputChangeBirthDate}
                      dateFormat="dd/MM/yyyy"
                    />
                    {errors.birthDate && (
                      <h4 className="text-error">{errors.birthDate}</h4>
                    )}
                  </FormGroup>
                )}
              </Col>
              {memberTypeId !== 11 ? (
                <>
                  <Col md="6">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_date_of_birth" />}
                      </Label>
                      <DatePicker
                        placeholderText="dd/mm/yyyy"
                        className="form-control"
                        selected={birthDate}
                        maxDate={new Date()}
                        showYearDropdown
                        onChange={handleInputChangeBirthDate}
                        dateFormat="dd/MM/yyyy"
                      />
                      {errors.birthDate && (
                        <h4 className="text-error">{errors.birthDate}</h4>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="member_native_town" />}
                      </Label>
                      <Input
                        className={GetSelectedLanguage() === "en" ? "" : "ur"}
                        type="text"
                        name="nativeTown"
                        value={memberValues.nativeTown}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </>
              ) : null}
              <Col md="6">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="member_cnic" />}
                  </Label>
                  <InputMask
                    mask="99999-9999999-9"
                    value={memberValues.cnic}
                    onChange={handleInputChangeCnic}
                    name="cnic"
                    maskChar=""
                  >
                    {(inputProps) => (
                      <Input
                        {...inputProps}
                        type="text"
                        placeholder="______-_______-_"
                      />
                    )}
                  </InputMask>
                </FormGroup>
                {memberTypeId !== 11 ? (
                  <FormGroup>
                    <Label className="col-form-label">
                      {<GetLanguageString props="member_blood_group" />}
                    </Label>
                    <Input
                      type="text"
                      name="bloodGroup"
                      value={memberValues.bloodGroup}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                ) : (
                  <Col md="6">
                    <FormGroup>
                      <Label className="col-form-label">
                        {<GetLanguageString props="ahbab_associated_member" />}
                      </Label>
                      <div
                        style={{
                          backgroundColor: "#e9ecef",
                          backgroundImage: "none",
                          border: "1px solid #e5e6e7",
                          borderRadius: "1px",
                          color: "inherit",
                          display: "block",
                          padding: "6px 12px",
                          transition:
                            "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                          width: "200%",
                          height: "36px",
                        }}
                      >
                        {memberValues.associatedMember
                          ? applyClassToCharacters(
                              memberValues.associatedMember,
                              "ur"
                            )
                          : ""}
                      </div>
                    </FormGroup>
                  </Col>
                )}
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="member_reference_note" />}
                  </Label>
                  <Input
                    className={GetSelectedLanguage() === "en" ? "" : "ur"}
                    type="text"
                    name="referenceNote"
                    value={memberValues.referenceNote}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                {memberTypeId !== 11 && isPreviousRecord ? (
                  currentRecordId === memberId ? (
                    <Row>
                      <Col md="8">
                        <FormGroup>
                          <Label className="col-form-label">
                            {
                              <GetLanguageString props="member_previous_record" />
                            }
                          </Label>
                          <Input
                            className={
                              GetSelectedLanguage() === "en" ? "" : "ur"
                            }
                            type="text"
                            name=""
                            disabled
                            value={memberValues.previousRecordRegNo}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        {memberValues.previousRecordId !== null &&
                        memberValues.previousRecordId !== 0 &&
                        memberValues.previousRecordId !== undefined ? (
                          <>
                            <ButtonGroup
                              className={GetCustomStyles().btn_group_style}
                              style={{ marginTop: "33px" }}
                            >
                              <Button
                                color="success"
                                onClick={() => {
                                  setMemberId(memberValues.previousRecordId);
                                }}
                              >
                                {<GetLanguageString props="member_view" />}
                              </Button>
                              <Button
                                color="danger"
                                onClick={deleteMemberPreviousRecord}
                              >
                                {<GetLanguageString props="member_remove" />}
                              </Button>
                            </ButtonGroup>
                          </>
                        ) : (
                          <Button
                            style={{ marginTop: "33px" }}
                            color="success"
                            onClick={() => {
                              setPreviousMemberModal(!previousMemberModal);
                            }}
                          >
                            {<GetLanguageString props="member_select" />}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col md="8">
                        <FormGroup>
                          <Label className="col-form-label">
                            {
                              <GetLanguageString props="member_current_record" />
                            }
                          </Label>
                          <Input
                            className={
                              GetSelectedLanguage() === "en" ? "" : "ur"
                            }
                            type="text"
                            name=""
                            disabled
                            value={currentRecordRegNo}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <Button
                          color="success"
                          style={{ marginTop: "33px" }}
                          onClick={() => {
                            setMemberId(currentRecordId);
                          }}
                        >
                          {<GetLanguageString props="member_view" />}
                        </Button>
                      </Col>
                    </Row>
                  )
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col md="8"></Col>

              <Col md="4">
                <div className={GetCustomStyles().btn_style}>
                  <Button color="white" onClick={handleCancel}>
                    {<GetLanguageString props="common_cancel" />}
                  </Button>
                  <Button color="primary" className="m-l-sm" type="submit">
                    {<GetLanguageString props="common_save_changes" />}
                  </Button>
                </div>
              </Col>
            </Row>
            {CheckPermission("CanRequestMemberModificationPersonalInfo") &&
            !CheckIsAdmin() &&
            !addNewRafiq ? (
              <div>
                <h4 className="text-error">* Request Modification</h4>
              </div>
            ) : null}
          </div>
        </Form>
      ) : (
        LoadingSpinner()
      )}
    </div>
  );
};

export default withRouter(UpdateMemberInfo);
