import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Row, Table, Col } from "reactstrap";
import moment from "moment";
import { saveAs } from "file-saver";

import {
  GetLanguageString,
  NoResult,
  SetReportTitle,
} from "../../../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../../../helper/Method";
import { LoadingSpinner } from "../../../../constants/const";
import { encryptStorage } from "../../../../constants/EncryptStorage";
import { addMember } from "../../../member/ApiMember";
import { FcPrint } from "react-icons/fc";
import { RiFileExcel2Line } from "react-icons/ri";
import SearchArrivalsDepartures from "./SearchArrivalsDepartures";

const ArrivalsDeparturesReport = () => {
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [reportTitle, setReportTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [newMembers, setNewMembers] = useState([]);
  const [transferredToMembers, setTransferredToMembers] = useState([]);
  const [transferredFromMembers, setTransferredFromMembers] = useState([]);
  const [leavingMembers, setLeavingMembers] = useState([]);
  const [resModel, setResModel] = useState({
    unitName: ""
  });
  const [searchButton, setSearchButton] = useState(false);

  let tempDate = new Date();
  tempDate.setUTCDate(1);
  const [values, setValues] = useState({
    unit: { value: loggedInUnitId, lable: "" },
    isSubUnits: true,
    startDate: tempDate,
    endDate: new Date(),
  });

  useEffect(() => {
    if (searchButton) {
      setLoading(true);
      addMember()
        .getArrivalsDeparturesReport(values)
        .then((res) => {
          setNewMembers(res.data.newMemberList);
          setTransferredToMembers(res.data.transferredToMemberList);
          setTransferredFromMembers(res.data.transferredFromMemberList);
          setLeavingMembers(res.data.leavingMemberList);
          setResModel(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [values, searchButton]);

  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  const handleReportTitle = () => {
    setModal(true);
  };

  const excelDownload = () => {
    resModel.endDate = values.endDate;
    resModel.startDate = values.startDate;

    addMember()
      .generateArrivalsDeparturesReport(resModel)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "ArrivalsDeparturesReport.xlsx");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox noprint">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="common_generate_report" />}</h5>
          </div>
          <SearchArrivalsDepartures
            memberStatusChangeValues={values}
            setMemberStatusChangeValues={setValues}
            setSearchButton={setSearchButton}
          />
        </div>
        {searchButton ? (
          <div className="ibox">
            <div className="ibox-title">
              <h3>
                <GetLanguageString
                  props="quick_reports5_title"
                  value={moment(values.startDate).format("DD/MM/yyyy")}
                />{" "}
                {moment(values.endDate).format("DD/MM/yyyy")})
              </h3>
              <ButtonGroup className={GetCustomStyles().err_btn_group_style}>
                {<Button color="default" size="sm" onClick={excelDownload}>
                  <RiFileExcel2Line
                    size={15}
                    color="#009440"
                    style={{ marginRight: 3, marginBottom: 1 }}
                  />
                  {<GetLanguageString props="member_report_export_excel" />}
                </Button> }
                <Button color="default" size="sm" onClick={handleReportTitle}>
                  <FcPrint size={15} className={GetCustomStyles().fa_style} />
                  {<GetLanguageString props="common_print" />}
                </Button>
              </ButtonGroup>
            </div>
            <div className="ibox-content ibox-center" id="section-to-print">
              <Row className="print">
                <div className="text-center">
                  <h3>Title : {reportTitle}</h3>
                </div>
              </Row>
              {!loading ? (
                <>
                <div className="panel panel-info">
                    <div className="panel-heading">
                      <h4 className="text-center">
                        <GetLanguageString props="quick_reports5_overview" />
                      </h4>
                    </div>
                    <div className="panel-body">
                    
                      <Row>
                        <Col md="2"></Col>
                        <Col md="4">
                          <Row>
                            <h1 className="ur">{resModel?.unitName}</h1>
                          </Row>
                        </Col>
                        <Col md="6">
                          <Row className="m-t-xs">
                              <Col md="5">
                                  <h4 className="m-b-xs"><GetLanguageString props="quick_reports5_arrival" />{" "}</h4>
                                  <h1 className="no-margins">{resModel?.arrivalsCount}</h1>
                              </Col>
                              <Col md="7">
                                  <h4 className="m-b-xs"><GetLanguageString props="quick_reports5_departures" />{" "}</h4>
                                  <h1 className="no-margins">{resModel?.departuresCount}</h1>
                              </Col>
                          </Row>
                          <Table className="m-t-sm">
                            <tbody>
                              <tr>
                                  <td>
                                    <strong>
                                    {resModel?.transferredToMemberCount}
                                    </strong>{" "}
                                    &ensp;
                                    <GetLanguageString props="quick_reports5_transferred_from_unit" />{" "}
                                  </td>
                                  <td style={GetSelectedLanguage() === "en" ? {
                                    paddingLeft: "55px"
                                  } : {
                                    paddingRight: "0px"
                                  }}
                                  >
                                    <strong>
                                    {resModel?.transferredFromMemberCount}
                                    </strong>{" "}
                                    &ensp;
                                    <GetLanguageString props="quick_reports5_transferred_to_unit" />{" "}
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                    <strong>
                                    {resModel?.newMemberCount}
                                    </strong>{" "}
                                    &ensp;
                                    <GetLanguageString props="quick_reports5_new_rufqa" />{" "}
                                  </td>
                                  <td style={GetSelectedLanguage() === "en" ? {
                                    paddingLeft: "55px"
                                  } : {
                                    paddingRight: "0px"
                                  }}
                                  >
                                    <strong>
                                    {resModel?.leavingMemberCount}
                                    </strong>{" "}
                                    &ensp;
                                    <GetLanguageString props="quick_reports5_rufqa_leaving" />{" "}
                                  </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="panel panel-primary">
                    <div className="panel-heading">
                      <h3 className="text-center">
                        <GetLanguageString props="quick_reports5_arrival" />
                      </h3>
                    </div>
                    <div className="panel-body">
                      <MemberList
                        name="quick_reports5_new_rufqa"
                        list={newMembers}
                      />
                      <TransferMemberList
                        name="quick_reports5_transferred_from_unit"
                        list={transferredToMembers} 
                      />
                    </div>
                  </div>
                  <div className="panel panel-primary">
                    <div className="panel-heading">
                      <h3 className="text-center">
                        <GetLanguageString props="quick_reports5_departures" />
                      </h3>
                    </div>
                    <div className="panel-body">
                      <TransferMemberList
                        name="quick_reports5_rufqa_leaving"
                        list={leavingMembers}
                      />
                      <TransferMemberList
                        name="quick_reports5_transferred_to_unit"
                        list={transferredFromMembers} 
                      />
                    </div>
                  </div>
                </>
              ) : (
                LoadingSpinner()
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ArrivalsDeparturesReport;

const MemberList = ({ name, list }) => {
  return (
    <div className="panel panel-success">
      <div className="panel-heading">
        <h4 className="text-center">
          <GetLanguageString props={name} />
        </h4>
      </div>
      {list != null && list?.length !== 0 ? (
        <div className="table-responsive">
          <Table className="table-bordered m-b-none">
            <thead>
              <tr>
                <th>#</th>
                <th>
                  <GetLanguageString props="member_reg_number" />
                </th>
                <th>
                  <GetLanguageString props="common_name" />
                </th>
                <th>
                  <GetLanguageString props="member_father_name" />
                </th>
                <th>{<GetLanguageString props="member_address" />}</th>
                <th>{<GetLanguageString props="member_phone" />}</th>
                <th>
                  <GetLanguageString props="quick_reports3_unit_name" />
                </th>
                <th>{<GetLanguageString props="quick_reports5_date_associated" />}</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((record, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{record.regNo}</td>
                    <td className="ur">{record.fullName}</td>
                    <td className="ur">{record.fatherName}</td>
                    <td className="ur">{record.currentAddress}</td>
                    <td>
                      {record.phone != null
                        ? record.phone
                            .split(",")
                            .map((phoneNumber, index) => (
                              <div key={index}>{phoneNumber}</div>
                            ))
                        : ""}
                    </td>
                    <td className="ur">{record.unitName}</td>
                    <td>{moment(record.dateAssociated).format("DD/MM/yyyy")}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <NoResult />
      )}
    </div>
  );
};

const TransferMemberList = ({ name, list }) => {
  let listLength = 0;
  return (
    <div className="panel panel-success">
      <div className="panel-heading">
        <h4 className="text-center">
          <GetLanguageString props={name} />
        </h4>
      </div>
      {list != null && list?.length !== 0 ? (
        <div className="table-responsive">
          <Table className="table-bordered m-b-none">
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">
                  <GetLanguageString props="member_reg_number" />
                </th>
                <th className="text-center">
                  <GetLanguageString props="common_name" />
                </th>
                <th className="text-center">
                  <GetLanguageString props="member_father_name" />
                </th>
                <th className="text-center">{<GetLanguageString props="member_address" />}</th>
                <th className="text-center">{<GetLanguageString props="member_phone" />}</th>
                
                <th className="text-center">{<GetLanguageString props="quick_reports5_pervious_unit" />}</th>
                <th className="text-center">{<GetLanguageString props="quick_reports5_current_unit" />}</th>
                <th className="text-center">{<GetLanguageString props="quick_reports5_date_associated" />}</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((record, index) => {
                listLength = record.unitList.length;
                return (
                  <>
                  {
                    record?.unitList.map((record1, indx) => {
                      return (
                          <tr key={index + "-" + indx}>
                            {indx === 0 ? (
                              <>
                                <td rowSpan={listLength} className="text-center">{index + 1}</td>
                                <td className="text-center" rowSpan={listLength}>{record.regNo}</td>
                                <td className="ur text-center" rowSpan={listLength}>{record.fullName}</td>
                                <td className="ur text-center" rowSpan={listLength}>{record.fatherName}</td>
                                <td className="ur text-center " rowSpan={listLength}>{record.currentAddress}</td>
                                <td className="text-center" rowSpan={listLength}>
                                  {record.phone != null
                                    ? record.phone
                                        .split(",")
                                        .map((phoneNumber, index) => (
                                          <div key={index}>{phoneNumber}</div>
                                        ))
                                    : ""}
                                </td>
                              </>
                            ): null}
                            <td className="ur text-center">{record1.perviousUnitName}</td>
                            <td className="ur text-center">{record1.currectUnitName}</td>
                            <td className="text-center">{moment(record1.dateAssociated).format("DD/MM/yyyy")}</td>
                          </tr>
                      );
                    })
                  }
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <NoResult />
      )}
    </div>
  );
};
