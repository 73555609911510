import React, { useState } from "react";
import {
  Button,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { GetLanguageString } from "../helper/Components";
import AddressType from "./addressType/AddressType";
import Books from "./books/Books";
import ContactType from "./contactType/ContactType";
import CourseType from "./courseType/CourseType";
import Education from "./education/Education";
import EventType from "./eventType/EventType";
import LetterForCounselling from "./letterForCounselling/LetterForCounselling";
import MemberType from "./memberType/MemberType";
import NotificationType from "./notificationType/NotificationType";
import Pledges from "./pledges/Pledges";
import ResponsibilityType from "./responsibilityType/ResponsibilityType";
import TutorialCategory from "./tutorialCategory/TutorialCategory";
import UnitType from "./unitType/UnitType";
import CounsellingQuestions from "./letterForCounselling/CounsellingQuestions/CounsellingQuestions";
import DocumentType from "./documentType/DocumentTypes";
import SaveCropFile from "./helper/SaveCropFile";
import DocumentTag from "./documentTags/DocumentTags";

const Configuration = () => {
  const [activeTab, setActiveTab] = useState("course");
  const [modal, setModal] = useState(false);

  const tabs = [
    {
      id: "course",
      label: "settings_course_type",
      component: <CourseType activeTab={activeTab} />,
    },
    {
      id: "event",
      label: "settings_event_type",
      component: <EventType activeTab={activeTab} />,
    },
    {
      id: "member",
      label: "settings_member_type",
      component: <MemberType activeTab={activeTab} />,
    },
    {
      id: "unit",
      label: "dashboard_event_page_unit_type",
      component: <UnitType activeTab={activeTab} />,
    },
    {
      id: "responsibility",
      label: "settings_responsibilities",
      component: <ResponsibilityType activeTab={activeTab} />,
    },
    {
      id: "books",
      label: "settings_books",
      component: <Books activeTab={activeTab} />,
    },
    {
      id: "notification",
      label: "settings_notification_type",
      component: <NotificationType activeTab={activeTab} />,
    },
    {
      id: "category",
      label: "tutorial_category",
      component: <TutorialCategory activeTab={activeTab} />,
    },
    {
      id: "education",
      label: "member_education",
      component: <Education activeTab={activeTab} />,
    },
    {
      id: "pledges",
      label: "member_member_detail_select_pledges_type",
      component: <Pledges activeTab={activeTab} />,
    },
    {
      id: "contactType",
      label: "settings_contact_type",
      component: <ContactType activeTab={activeTab} />,
    },
    {
      id: "addressType",
      label: "settings_address_type",
      component: <AddressType activeTab={activeTab} />,
    },
    {
      id: "letterForCounselling",
      label: "settings_letter_for_counselling",
      component: <LetterForCounselling activeTab={activeTab} />,
    },
    {
      id: "counsellingQuestions",
      label: "settings_counselling_questions",
      component: <CounsellingQuestions activeTab={activeTab} />,
    },
    {
      id: "documentType",
      label: "settings_document_types",
      component: <DocumentType activeTab={activeTab} />,
    },
    {
      id: "documentTags",
      label: "document_document_tags",
      component: <DocumentTag activeTab={activeTab} />,
    },
  ];
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="wrapper wrapper-content wrapper-custom">
      {modal ? <SaveCropFile /> : null}
      <Row>
        <Col md={12}>
          <Button className='m-b float-right' color='success' size='sm' onClick={() => setModal(true)}>Call the Api</Button>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <div className="tabs-container">
            <Nav tabs>
              {tabs.map((tab) => (
                <NavItem key={tab.id}>
                  <NavLink
                    className={activeTab === tab.id ? "active" : ""}
                    onClick={() => toggleTab(tab.id)}
                  >
                    <GetLanguageString props={tab.label} />
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              {tabs.map((tab) => (
                <TabPane key={tab.id} tabId={tab.id}>
                  <div className="panel-body">{tab.component}</div>
                </TabPane>
              ))}
            </TabContent>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Configuration;
