import axios from "axios";

import { BASEURL } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";

export function ApiDocumentTag() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const url = BASEURL + "setup/";
  return {
    AddDocumentTag: (record) => authAxios.post(url + "AddDocumentTag/", record),
    GetDocumentTags: () => authAxios.get(url + "GetDocumentTags/"),
    DeleteDocumentTag: (id) =>
      authAxios.delete(url + "DeleteDocumentTag/" + id),
    updateDocumentTag: (record) =>
      authAxios.post(url + `EditDocumentTag`, record),
  };
}
