import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Input,
  Button,
  Col,
  Row,
  FormFeedback,
} from "reactstrap";

import { GetSelectedLanguage, GetCustomStyles } from "../helper/Method";
import Select from "react-select";
import { customOption, LoadingSpinner } from "../../constants/const";
import { ApiTutorials } from "./ApiTutorials";
import swal from "sweetalert";
import { t } from "i18next";
import { GetLanguageString } from "../helper/Components";
import DatePicker from "react-datepicker";
import AddCategory from "./AddCategory";
import Draggable from "react-draggable";
import { IoAdd } from "react-icons/io5";

const AddTutorial = ({ setShowAddForm, refresh, setRefresh, setSearch }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    categoryId: 0,
    url: "",
    dateAdded: new Date(),
  });
  const [errors, setErrors] = useState();
  const [category, setCategory] = useState();
  const [selectCategory, setSelectCategory] = useState({});
  const [showCategory, setShowCategory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const [buttonDisable, setButtonDisable] = useState(false);
  const [refreshCategory, setRefreshCategory] = useState(false);
  const toggle = () => setShowAddForm(false);

  useEffect(() => {
    setLoading(true);
    ApiTutorials()
      .getCategory()
      .then((res) => {
        setCategory(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [refreshCategory]);

  const validate = () => {
    let temp = {};
    temp.title = formData.title ? (
      ""
    ) : (
      <GetLanguageString props="tutorial_title_error_message" />
    );
    temp.category =
      Object.keys(selectCategory).length !== 0 ? (
        ""
      ) : (
        <GetLanguageString props="tutorial_category_error_message" />
      );
    temp.url = formData.url ? (
      ""
    ) : (
      <GetLanguageString props="events_date_error_message" />
    );
    temp.dateAdded = formData.dateAdded ? (
      ""
    ) : (
      <GetLanguageString props="tutorial_date_error_message" />
    );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleTutorialCategory = (record) => {
    setFormData({
      ...formData,
      categoryId: record.value,
    });
    setSelectCategory(record);
    setErrors({
      ...errors,
      category: "",
    });
  };

  const handleInputChangeDate = (value) => {
    if (value <= new Date()) {
      setFormData({
        ...formData,
        dateAdded: value,
      });
      setErrors({
        ...errors,
        dateAdded: "",
      });
    } else {
      setErrors({
        ...errors,
        dateAdded: t("tutorial_date_error_message"),
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formData.categoryId = selectCategory.value;
    if (validate()) {
      setLoading(true);
      setButtonDisable(true);
      ApiTutorials()
        .addTutorials(formData)
        .then((res) => {
          swal({
            title: t("video_tutorial_added_msg"),
            className: GetSelectedLanguage() === "en" ? "" : "ur",
            icon: "success",
            buttons: GetSelectedLanguage() === "en" ? "OK" : "ٹھیک ہے ",
          });
          setLoading(false);
          setButtonDisable(false);
          setSearch(true);
          setRefresh(!refresh);
          toggle();
        })
        .catch((err) => {
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
          setButtonDisable(false);
        });
    }
  };

  const handleCategory = () => {
    setShowCategory(true);
  };

  return (
    <div>
      {showCategory ? (
        <AddCategory
          setShowCategory={setShowCategory}
          refreshCategory={refreshCategory}
          setRefreshCategory={setRefreshCategory}
          setSelectCategory={setSelectCategory}
        />
      ) : null}
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            <GetLanguageString props="video_tutorial_add_tutorial" />
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label>
                    <GetLanguageString props="settings_title" />
                  </Label>
                  <Input
                    autoFocus
                    type="text"
                    name="title"
                    className="no-drag"
                    value={formData.title}
                    onChange={handleInputChange}
                    {...(errors?.title && { invalid: true })}
                  />
                  {errors?.title && (
                    <FormFeedback>
                      <h4>{errors?.title}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>
                    <GetLanguageString props="video_tutorial_category" />
                  </Label>
                  <Row>
                    <Col md="9">
                      <Select
                        options={category}
                        value={selectCategory}
                        className="basic-single no-drag"
                        classNamePrefix="select"
                        components={{
                          Option: customOption,
                        }}
                        onChange={handleTutorialCategory}
                      />
                      {errors?.category ? (
                        <h4 className="text-error">{errors?.category}</h4>
                      ) : null}
                    </Col>
                    <Col md="3">
                      <Button
                        className={GetCustomStyles().btn_style}
                        color="default"
                        onClick={handleCategory}
                      >
                        {" "}
                        <IoAdd
                          color="#18A689"
                          size={18}
                          style={{ marginRight: 3, marginBottom: 1 }}
                        />
                        {<GetLanguageString props="tutorial_add_category" />}
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="funds_date" />}
                  </Label>
                  <DatePicker
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mm/yyyy"
                    className="form-control no-drag"
                    selected={formData.dateAdded}
                    onChange={handleInputChangeDate}
                    dateFormat="dd/MM/yyyy"
                  />
                  {errors?.dateAdded && (
                    <FormFeedback>
                      <h4 className="text-error">{errors?.dateAdded}</h4>
                    </FormFeedback>
                    // <h4 className="text-error">{errors?.dateAdded}</h4>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>
                    <GetLanguageString props="video_tutorial_url" />
                  </Label>
                  <Input
                    type="text"
                    name="url"
                    className="no-drag"
                    value={formData.url}
                    onChange={handleInputChange}
                    {...(errors?.url && { invalid: true })}
                  />
                  {errors?.url && (
                    <FormFeedback>
                      <h4>{errors?.url}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>
                    <GetLanguageString props="courses_description" />
                  </Label>
                  <Input
                    type="text"
                    name="description"
                    className="no-drag"
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  <GetLanguageString props="common_cancel" />
                </Button>
                <Button color="primary" type="submit" disabled={buttonDisable}>
                  <GetLanguageString props="common_save" />
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default AddTutorial;
