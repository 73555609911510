import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup } from "reactstrap";

import {
  LoadingSpinner
} from "../../constants/const";
import { ApiEvent } from "../events/ApiEvent";
import { GetLanguageString, NoResult } from "../helper/Components";
import { GetCustomStyles, GetSelectedLanguage } from "../helper/Method";

const UpcomingEvents = ({ tempUnit, tab4 }) => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [oneMonthEvent, setOneMonthEvent] = useState("primary");
  const [sixMonthEvent, setSixMonthEvent] = useState("white");
  const [yearEvent, setYearEvent] = useState("white");
  let noTopBorder = "col content";
  let currentDate = new Date();
  let lastDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    currentDate.getDate(),
    5
  );
  const [searchEventModel, setSearchEventModel] = useState({
    unit: tempUnit,
    startDate: currentDate,
    endDate: lastDay,
    isSubUnits: true,
  });

  useEffect(() => {
    if (tab4 === "active") {
      setLoading(true);
      ApiEvent()
        .get(searchEventModel)
        .then((res) => {
          setEvents(res.data.events);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [searchEventModel, tab4]);

  const handleEventDate = (e) => {
    const { value } = e.target;
    let date = new Date();
    if (value === "0") {
      setOneMonthEvent("primary");
      setSixMonthEvent("white");
      setYearEvent("white");
      setSearchEventModel({
        ...searchEventModel,
        startDate: currentDate,
        endDate: lastDay,
      });
    } else if (value === "1") {
      setOneMonthEvent("white");
      setYearEvent("white");
      setSixMonthEvent("primary");
      lastDay = new Date(
        date.getFullYear(),
        date.getMonth() + 6,
        date.getDate(),
        5
      );
      setSearchEventModel({
        ...searchEventModel,
        startDate: currentDate,
        endDate: lastDay,
      });
    } else if (value === "2") {
      setYearEvent("primary");
      setOneMonthEvent("white");
      setSixMonthEvent("white");
      lastDay = new Date(
        date.getFullYear(),
        date.getMonth() + 12,
        date.getDate(),
        5
      );
      setSearchEventModel({
        ...searchEventModel,
        startDate: currentDate,
        endDate: lastDay,
      });
    }
  };

  return (
    <div className="ibox">
      <div className="ibox-title">
        <h5>{<GetLanguageString props="dashboard_upcomming_events" />}</h5>
        <div className={GetCustomStyles().btn_style}>
          <ButtonGroup>
            <Button
              size="xs"
              color={oneMonthEvent}
              value={0}
              onClick={handleEventDate}
            >
              {<GetLanguageString props="dashboard_btn_1month" />}
            </Button>
            <Button
              size="xs"
              color={sixMonthEvent}
              value={1}
              onClick={handleEventDate}
            >
              {<GetLanguageString props="dashboard_btn_6month" />}
            </Button>
            <Button
              size="xs"
              color={yearEvent}
              value={2}
              onClick={handleEventDate}
            >
              {<GetLanguageString props="dashboard_btn_year" />}
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <div className="ibox-content inspinia-timeline">
        {!loading ? (
          <>
            {events.length !== 0 ? (
              events.map((record, index) => {
                index === 0
                  ? (noTopBorder = "col content no-top-border")
                  : (noTopBorder = "col content");
                return (
                  <div className="timeline-item" key={index}>
                    <div className="row">
                      <div className="col-4 date">
                        {moment.utc(record.date)
                          .locale(GetSelectedLanguage())
                          .format("MMMM DD, yyyy")}
                        <br />
                        <small className="text-navy">
                          {GetSelectedLanguage() === "ur" ? moment.utc(record.date)
                            .locale(GetSelectedLanguage())
                            .format("a\u00a0h:mm")  // Using a non-breaking space (&nbsp;)
                            : moment.utc(record.date)
                            .locale(GetSelectedLanguage())
                            .format("h:mm a")
                            }
                        </small>
                      </div>

                      <div className={noTopBorder}>
                        <p className="m-b-xs">
                          <strong className="ur">
                            {record.eventType.label} ({record.unit.label})
                          </strong>
                        </p>
                        <p className="ur">{record.address}</p>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <NoResult />
            )}
          </>
        ) : (
          LoadingSpinner()
        )}
      </div>
    </div>
  );
};

export default UpcomingEvents;
